import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useParams, useNavigate, Link} from 'react-router-dom'
import {changePassword} from '../core/_requests'
import {brevoTrackEvent} from '../../brevo/_requests'

YupPassword(Yup)

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required()
      .minNumbers(1, 'Le mot de passe doit contenir au moins 1 chiffre')
      .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
      .minUppercase(1,'Le mot de passe doit contenir au moins 1 majuscule')
      .required('Mot de passe requis'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
    .required('Confirmation du mot de passe requise'),
})

export function ResetPassword() {
  const navigate = useNavigate()
  const {token} = useParams()
  console.log('Token:', token)
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      setLoading(true)
      if (!token) {
        navigate('/login')
        return
      }
      try {
        const response = await changePassword(values.password, token)
        if (response.message.includes('Success')) {
          setIsSuccess(true)
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        } else {
          setStatus(response.message)
          setHasErrors(true)
        }
      } catch (error) {
        setStatus("Une erreur s'est produite")
        setHasErrors(true)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = 'has_viewed_reset_password_page'
      const eventData = {}
      brevoTrackEvent(eventName, eventData)
      console.log('Success - brevoTrackEvent')
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Réinitialisation du Mot de Passe</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Entrez votre nouveau mot de passe</div>
      </div>

      {hasErrors && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Désolé, il semble que des erreurs aient été détectées, veuillez réessayer.
          </div>
        </div>
      )}

      {isSuccess && (
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold'>
            Votre mot de passe a été réinitialisé avec succès, vous allez être redirigé vers la page
            de connexion.
          </div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Nouveau mot de passe</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirmer le mot de passe</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
          })}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' className='btn btn-primary me-4'>
          Réinitialiser le mot de passe
          {loading && (
            <span className='indicator-progress'>
              S'il vous plaît, attendez...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/login'>
          <button
            type='button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Annuler
          </button>
        </Link>
      </div>
    </form>
  )
}

export {}
