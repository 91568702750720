import {Link} from "react-router-dom";
import React from "react";

const Community = ({customer}) => {

    return (
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0" data-bs-toggle="collapse"
             data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Ma communauté Sleem</h3>
          </div>
        </div>
        <div id="kt_account_settings_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row d-flex justify-content-left align-items-center">
              <div className="col-12">
                <div>
                  <p>Rejoignez notre communauté privée Sleem sur Facebook !
                      De nombreux Sleemers nous ont déjà rejoints pour partager leurs expériences,
                      échanger des conseils, participer à des challenges… et bien d’autres !</p>

                    <div className="text-center mb-10">
                        <div>
                        <a href='https://www.facebook.com/groups/sleemcommunaute'
                           target="_blank"
                           className="btn btn-danger btn-active-light-danger">
                          Je rejoins
                        </a>
                      </div>
                    </div>

                      <p>Découvrez aussi nos autres <b>réseaux sociaux</b> où nous publions chaque semaine des contenus exclusifs.
                  </p>
                </div>
              </div>
            </div>

              <div className="d-flex flex-center justify-content-center align-items-center">
                  <div className={"bg-primary bg-opacity-10 mt-3 rounded mb-3 p-4 rounded-md"} style={{maxWidth: "200px"}}>
                  <a href="https://www.facebook.com/sleemfrance/" target="_blank"><i className="socicon-facebook text-primary me-5 fs-1"></i></a>
                  <a href="https://www.instagram.com/sleem_fr/" target="_blank"><i className="socicon-instagram text-primary me-5 fs-1"></i></a>
                  <a href="https://www.youtube.com/channel/UCI7h_72UXY8mEm0QYXN8AHg" target="_blank"><i className="socicon-youtube text-primary me-5 fs-1"></i></a>
                  <a href="https://www.linkedin.com/company/sleem/about/" target="_blank"><i className="socicon-linkedin text-primary me-5 fs-1"></i></a>
                  </div>
              </div>

          </div>
        </div>
      </div>
    )
}

export default Community
