import React from 'react'
import TagManager from 'react-gtm-module'

const NavTabsRecipes = ({activeTab, setActiveTab}) => {
  const tabs = [
    {id: 'breakfasts', label: 'Petit-déjeuner'},
    {id: 'starters', label: 'Entrée'},
    {id: 'mains', label: 'Plat'},
    {id: 'desserts', label: 'Dessert'},
    {id: 'bonuses', label: 'Bonus'},
  ]

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab)

    if (newTab === 'breakfasts') {
      // GTM TRACKING
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewed-recipes-breakfasts',
        },
      })
    } else if (newTab == 'starters') {
      // GTM TRACKING
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewed-recipes-starters',
        },
      })
    } else if (newTab === 'mains') {
      // GTM TRACKING
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewed-recipes-mains',
        },
      })
    } else if (newTab === 'desserts') {
      // GTM TRACKING
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewed-recipes-desserts',
        },
      })
    } else if (newTab === 'bonuses') {
      // GTM TRACKING
      TagManager.dataLayer({
        dataLayer: {
          event: 'viewed-recipes-bonuses',
        },
      })
    }
  }

  return (
    <ul className='nav nav-tabs nav-justified flex flex-md-row list-none border-b-0 pl-0 mt-lg-0 mb-4'>
      {tabs.map((tab) => (
        <li
          key={tab.id}
          className='nav-item flex-grow text-center cursor-pointer'
          role='presentation'
        >
          <div
            className={`nav-link w-100 block font-weight-bold h1 leading-tight border-x-0 border-t-0
                            border-b-2 px-2 py-3 my-2  ${
                              activeTab === tab.id
                                ? 'active border-b-2 text-pink'
                                : 'border-transparent text-black'
                            }`}
            onClick={() => handleTabChange(tab.id)}
          >
            {tab.label}
          </div>
        </li>
      ))}
    </ul>
  )
}

export default NavTabsRecipes
