/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../_metronic/helpers'
import {useState} from 'react'
import {getPdfFile} from "../../planning/core/_requests";
import {useParams} from 'react-router-dom'

const ToolbarRecipe = () => {
  const [isNotifVisible, setNotifVisible] = useState(false)
  const [isDownloadNotifVisible, setDownloadNotifVisible] = useState(false)
  const {id} = useParams()

  /**
   * The function `handleNotifClick` sets the visibility of a notification to true, and then after 5
   * seconds, sets the visibility to false.
   * @returns The function returned is a cleanup function that clears the timeout using clearTimeout.
   */
  const handleNotifClick = () => {
    setNotifVisible(true)
    const timerId = setTimeout(() => {
      setNotifVisible(false)
    }, 5000)

    return () => {
      clearTimeout(timerId)
    }
  }

  /**
   * The function handleCloseNotif is used to close a notification by setting its visibility to false.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data related to the event. In this case, the event parameter is used to stop
   * the event from propagating further up
   */
  const handleCloseNotif = (event) => {
    event.stopPropagation()
    setNotifVisible(false)
  }

  /**
   * The function `handleDownloadClick` is an asynchronous function that retrieves a planning file for
   * a customer and displays a download notification.
   * @returns The function `handleDownloadClick` does not have a return statement.
   */
  const handleDownloadClick = async () => {
    try {
      const customerId = localStorage.getItem('customer_id')

      if (!customerId || !id) {
        console.error('Informations nécessaires pour le téléchargement manquantes.')
        return
      }

      await getPdfFile(customerId, '', id, 'recipe')
      showDownloadNotification()
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier de planning:', error)
    }
  }

  /**
   * The function `showDownloadNotification` sets a download notification to be visible for 5 seconds
   * and then hides it.
   */
  const showDownloadNotification = () => {
    setDownloadNotifVisible(true)
    setTimeout(() => {
      setDownloadNotifVisible(false)
    }, 5000)
  }

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div className='m-0 btn-planning'>
        <a id='download-file' className='btn btn-icon' onClick={handleDownloadClick}>
          <span className='svg-icon svg-icon-primary svg-icon-2x'>
            <KTSVG
              path='/media/svg/icons-sleem/mail-attachment.svg'
              className='svg-icon-2x svg-icon'
            />
          </span>
          <div
            id='notif'
            className={`position-fixed flex-column box w-50 w-lg-325px shadow-lg bg-success ${
              isDownloadNotifVisible ? '' : 'd-none'
            }`}
          >
            <div
              id='header'
              className='d-flex py-1 flex-row justify-content-between align-items-center'
            >
              <div className='d-flex flex-row align-items-center gap-2 px-4 py-2 text-white w-100'>
                <i id='icone' className='far fa-check-circle text-white fs-4'></i>
                <span className='fs-5' id='title'>
                  Demande envoyée
                </span>
              </div>

              <button
                id='close_notification'
                type='button'
                className='btn btn-icon-success'
                onClick={handleCloseNotif}
              >
                <span className='svg-icon svg-icon-1 close-notif'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='#ffff'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='#ffff'
                    ></rect>
                  </svg>
                </span>
              </button>
            </div>
            <div
              id='description'
              className='d-flex align-items-center text-dark bg-white px-4 py-4'
            >
              Vous allez recevoir votre document par email d'ici quelques minutes !
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export {ToolbarRecipe}
