import React, {useState} from "react";
import {changePassword, logout} from "../../modules/auth/core/_requests";
import NotificationSuccess from "./Notifications";
const API_URL = process.env.REACT_APP_API_URL


async function requestPasswordFromApp(username: string, customerId: string) {
  try {
    const REQUEST_PASSWORD_FROM_APP_URL = `${API_URL}/customers/${customerId}/reset_password_from_app/`
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(REQUEST_PASSWORD_FROM_APP_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({}),
    })
    if (!response.ok) {
      throw new Error('Erreur lors de la requête')
    }
    const data = await response.json()
    return data.reset_token
  } catch (error) {
    console.error('Erreur lors de la requête', error)
    throw error
  }
}


const PasswordResetFormInApp = ({username, customerId}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [notifVisibility, setNotifVisibility] = useState(false)

  const validatePassword = () => {
    if (password.length < 8 || !/\d/.test(password) || !/[A-Z]/.test(password)) {
      setError('Le mot de passe doit contenir au moins 8 caractères, une majuscule et un chiffre.');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return false;
    }
    setError('');
    return true;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePassword()) {
      // Logique de réinitialisation du mot de passe
      requestPasswordFromApp(username, customerId).then(r =>
          changePassword(password, r)
      )
      setNotifVisibility(true)
    }
  };

  return (
      <>
<NotificationSuccess show={notifVisibility} title={"Succès"} message={"Votre mot de passe a bien été réinitialisé"}/>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0" data-bs-toggle="collapse"
             data-bs-target="#kt_account_profile_details" aria-expanded="true"
             aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Modification de mot de passe</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_reset_password_form" className="form" onSubmit={handleSubmit}>
            <div className="card-body border-top p-9">

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">Nouveau mot de passe</label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input type="password" value={password}
                             onChange={(e) => setPassword(e.target.value)}
                             className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                             placeholder="Nouveau mot de passe"/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">Confirmation de mot de passe</label>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6 fv-row">
                      <input type="password"
                             className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                             value={confirmPassword}
                             onChange={(e) => setConfirmPassword(e.target.value)}
                             placeholder="Confirmer le mot de passe"/>
                      {error && <p className={'mt-5 text-danger fw-bold my-3'}>{error}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row-reverse mt-10">
                <div id="kt_signin_password_button" className="ms-auto">
                  <button type="submit" className={"btn btn-danger btn-active-light-danger"}>Réinitialiser</button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
        </>
  );
};

export default PasswordResetFormInApp;
