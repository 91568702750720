import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getAdditionalRecipe} from '../../core/_requests'

const URL_IMG_RECIPES = process.env.REACT_APP_URL_IMG_RECIPES
const DEFAULT_IMAGE = '../public/media/images/plat-default.jpeg'

type Props = {
  className: string
}

const TAG_COLORS = {
  'Protéines Végétales': 'success',
  'Viande blanche': 'info',
  'Viande rouge': 'danger',
  Poisson: 'primary',
  Oeuf: 'warning',
}

const RECIPE_TYPE_MAPPING = {
  breakfast: 'Petits-déjeuners',
  dessert: 'Desserts',
  bonus: 'Bonus',
}

const AdditionalRecipes: React.FC<Props> = ({className}) => {
  const {id} = useParams<{id: string}>()
  const [activeTab, setActiveTab] = useState('breakfast')
  const [recipes, setRecipes] = useState<{[type: string]: any[]}>({})

  /* The `useEffect` hook is used to fetch additional recipes based on the `id` parameter whenever it
  changes. */
  useEffect(() => {
    const fetchRecipes = async () => {
      if (!id) return
      try {
        const breakfastRecipes = await getAdditionalRecipe(id, 'breakfast')
        const dessertRecipes = await getAdditionalRecipe(id, 'dessert')
        const bonusRecipes = await getAdditionalRecipe(id, 'bonus')
        setRecipes({
          breakfast: breakfastRecipes,
          dessert: dessertRecipes,
          bonus: bonusRecipes,
        })
      } catch (error) {
        console.error('Error fetching recipes:', error)
      }
    }

    fetchRecipes()
  }, [id])

  return (
    <>
      <h3 className='title-add'>Mes recettes additionnelles</h3>
      <p className='text-muted'>
        Retrouvez plus d'informations sur vos prises alimentaires dans l'onglet "Conseils"
      </p>
      <div className={`card card-additional-recipe ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-3 px-3 px-md-5 pt-md-5'>
          <div className='card-toolbar card-add'>
            <ul className='nav nav-add'>
              {['breakfast', 'dessert', 'bonus'].map((type) => (
                <li className='nav-item' key={type}>
                  <a
                    className={`nav-link btn btn-sm btn-color-muted btn-active text-hover-white text-active-white rounded-pill min-w-45px py-4 px-3 ${
                      activeTab === type ? 'active' : ''
                    } fw-bold`}
                    onClick={() => setActiveTab(type)}
                    href={`#kt_table_widget_5_tab_${type}`}
                  >
                    {RECIPE_TYPE_MAPPING[type]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* end::Header */}
        {/* Body */}
        <div className='card-body py-3 px-md-8 px-3'>
          <div className='tab-content'>
            {['breakfast', 'dessert', 'bonus'].map((type) => (
              <div
                className={`tab-pane fade ${activeTab === type ? 'show active' : ''}`}
                id={`kt_table_widget_5_tab_${type}`}
                key={type}
              >
                {/* Table container */}
                <div className='table-responsive'>
                  {/* Table */}
                  <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='border-0'>
                        <th className='p-0 w-50px'></th>
                        <th className='p-0 min-w-100px'></th>
                        <th className='p-0 min-w-80px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* Table body */}
                    <tbody>
                      {recipes[type]?.map((recipe, index) => (
                        <tr key={index}>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <div
                                className='symbol w-12 h-12 me-3'
                                style={{
                                  backgroundImage: `url(${
                                    recipe.main_picture
                                      ? URL_IMG_RECIPES + recipe.main_picture
                                      : DEFAULT_IMAGE
                                  })`,
                                  backgroundSize: 'cover',
                                  width: '50px',
                                  height: '50px',
                                }}
                              ></div>
                            </div>
                          </td>
                          <td className='recipe-name-tag d-flex justify-content-start flex-column px-0'>
                            <Link
                              to={`/recipe/${recipe.id}`}
                              state={{from: 'my-planning', planningId: id}}
                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {recipe.name}
                            </Link>
                            {recipe.tags &&
                              recipe.tags.length > 0 &&
                              recipe.tags[0].name !== 'No tag' &&
                              TAG_COLORS[recipe.tags[0].name] && (
                                <span
                                  className={`badge py-3 px-4 fs-9 badge-light-${
                                    TAG_COLORS[recipe.tags[0].name]
                                  } flex-wrap`}
                                >
                                  {recipe.tags[0].name}
                                </span>
                              )}
                          </td>
                          <td className='btn-edit-access'>
                            <Link
                              to={`/recipe/${recipe.id}`}
                              state={{from: 'my-planning', planningId: id}}
                              className='btn btn-sm btn-icon btn-bg-danger btn-active-color-primary w-30px h-30px'
                            >
                              <i className='fas fa-arrow-right text-white'></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export {AdditionalRecipes}
