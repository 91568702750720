import React, {useEffect, useState} from 'react'
import {AllShedules} from '../PlanningModels'
import {KTSVG, KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {getAllPlanningsByCustomer} from '../core/_requests'

const AllPlanningWidget: React.FC<{className: string}> = ({className}) => {
  const [plannings, setPlannings] = useState<AllShedules[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchAllPlannings = async () => {
      const customerId = localStorage.getItem('customer_id')
      if (customerId) {
        const data = await getAllPlanningsByCustomer(customerId)
        setPlannings(data)

        setIsLoading(false)

      }
    }

    fetchAllPlannings()
  }, [])

  let planningIndex = plannings.length

  if (isLoading) return <div>Chargement...</div>

  return (
    <>
      {plannings.map((planning) => (
        <div key={planning.id} className={`col-xl-6`}>
          <div className={`card ${className} ${planning.status || planning.status === 2 ? 'planning-doing' : ''}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>
                  {planning.status === 1 || planning.status === 2
                    ? `Planning en cours de création #${planningIndex--}`
                    : `Planning #${planning.status === 3 ? planningIndex-- : ''}`}
                </span>
                <span className='text-muted fw-semibold fs-7'>
                  Du{' '}
                  {new Date(planning.start_date).toLocaleDateString('fr-FR', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}{' '}
                  au{' '}
                  {new Date(planning.end_date).toLocaleDateString('fr-FR', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </span>
              </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body py-5'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-5'>
                  {/* begin::Table body */}
                  <tbody className='d-flex flex-wrap'>
                    {planning.status === 3 ? (
                      <>
                        {/* Mon planning */}
                        <tr
                          id='link-my-planning'
                          className='align-items-center bg-success bg-opacity-25 align-basaline rounded px-8 col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        >
                          <td>
                            <Link to={`/my-planning/${planning.id}`}>
                              <span className='menu-icon'>
                                <KTSVG
                                  path='/media/svg/icons-sleem/calendar-widget.svg'
                                  className='svg-svg-icon svg-icon-2x svg-icon-success'
                                />
                              </span>
                            </Link>
                          </td>
                          <td className='w-full'>
                            <Link
                              to={`/my-planning/${planning.id}`}
                              className='text-dark font-semibold text-hover-success mb-1 fs-6'
                            >
                              Mon planning
                            </Link>
                          </td>
                          <td className='text-end'>
                            <Link
                              to={`/my-planning/${planning.id}`}
                              className='btn btn-sm btn-icon btn-bg-success w-30px h-30px'
                            >
                              <KTIcon
                                iconName='arrow-right'
                                className='fs-1 svg-icon svg-icon-2x svg-icon-white'
                              />
                            </Link>
                          </td>
                        </tr>

                        {/* Ma liste de courses */}
                        <tr
                          id='link-course-list'
                          className='align-items-center bg-danger bg-opacity-25 rounded px-8 col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        >
                          <td>
                            <Link to={`/shopping-list/${planning.id}`}>
                              <span className='menu-icon'>
                                <KTSVG
                                  path='/media/svg/icons-sleem/basket-widget.svg'
                                  className='svg-icon svg-icon-2x svg-icon-danger'
                                />
                              </span>
                            </Link>
                          </td>
                          <td className='w-full'>
                            <Link
                              to={`/shopping-list/${planning.id}`}
                              className='text-dark font-semibold text-hover-primary mb-1 fs-6'
                            >
                              Ma liste de courses {planning.shopping_list_up_to_date ? ("") : ("(⚠️ la liste est en train d'être mise à jour)")}
                            </Link>
                          </td>
                          <td className='text-end'>
                            <Link
                              to={`/shopping-list/${planning.id}`}
                              className='btn btn-sm btn-icon btn-bg-danger w-30px h-30px'
                            >
                              <KTIcon
                                iconName='arrow-right'
                                className='fs-1 svg-icon svg-icon-5 svg-icon-white'
                              />
                            </Link>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {/* Mon planning (désactivé) */}
                        <tr
                          id='link-my-planning'
                          className='align-items-center bg-gray-200 align-basaline rounded px-8 col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        >
                          <td>
                            <span className='menu-icon'>
                              <KTSVG
                                path='/media/svg/icons-sleem/calendar-widget.svg'
                                className='svg-icon svg-icon-2x svg-icon-dark'
                              />
                            </span>
                          </td>
                          <td className='w-full'>
                            <span className='text-gray-500 font-semibold mb-1 fs-6'>
                              Mon planning
                            </span>
                          </td>
                          <td className='text-end'>
                            <span className='btn btn-sm btn-icon btn-bg-secondary btn-light-dark w-30px h-30px'>
                              <KTIcon
                                iconName='arrow-right'
                                className='fs-1 svg-icon svg-icon-2x svg-icon-white'
                              />
                            </span>
                          </td>
                        </tr>

                        {/* Ma liste de courses (désactivé) */}
                        <tr
                          id='link-course-list'
                          className='align-items-center bg-gray-200  rounded px-8 col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        >
                          <td>
                            <span className='menu-icon'>
                              <KTSVG
                                path='/media/svg/icons-sleem/basket-widget.svg'
                                className='svg-icon svg-icon-2x svg-icon-dark'
                              />
                            </span>
                          </td>
                          <td className='w-full'>
                            <span className='text-gray-500 font-semibold mb-1 fs-6'>
                              Ma liste de courses
                            </span>
                          </td>
                          <td className='text-end'>
                            <span className='btn btn-sm btn-icon btn-bg-secondary btn-light-dark w-30px h-30px'>
                              <KTIcon
                                iconName='arrow-right'
                                className='fs-1 svg-icon svg-icon-5 svg-icon-white'
                              />
                            </span>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* end::Body */}
          </div>
        </div>
      ))}
    </>
  )
}

export {AllPlanningWidget}
