/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useReplaceUnitName} from '../../../../helpers'
import {KTSVG} from '../../../../helpers'

type Props = {
  className: string
  ingredients: any[]
  informations: {
    preparation_time_min: number
    cooking_time_min: number
    kcal_per_recipe_per_person: number
    price_per_person: number
  }
}

const ShowMealRecipe: React.FC<Props> = ({className, ingredients, informations}) => {
  const replaceUnitName = useReplaceUnitName()

  return (
    <>
      <div className='d-flex justify-content-between align-items-center gap-2 mb-5'>
        <div className='badge fs-8 gap-1 bg-danger bg-opacity-10 badge-danger'>
          <KTSVG
            path='/media/svg/icons-sleem/roll.svg'
            className='svg-icon svg-icon-primary svg-icon-2x'
          />
          <span className='text-primary'>{informations.preparation_time_min} min</span>
        </div>

        <div className='badge fs-8 gap-1 bg-danger bg-opacity-10 badge-danger'>
          <KTSVG
            path='/media/svg/icons-sleem/glove.svg'
            className='svg-icon svg-icon-primary svg-icon-2x'
          />

          <span className='text-primary'>{informations.cooking_time_min} min</span>
        </div>

        <div className='badge fs-8 gap-1 bg-danger bg-opacity-10 badge-danger'>
          <KTSVG
            path='/media/svg/icons-sleem/calculator.svg'
            className='svg-icon svg-icon-primary svg-icon-2x'
          />
          <span className='text-primary'>{informations.kcal_per_recipe_per_person.toFixed(0)} Kcal</span>
        </div>

        <div className='badge fs-8 gap-1 bg-danger bg-opacity-10 badge-danger'>
          <KTSVG
            path='/media/svg/icons-sleem/euro.svg'
            className='svg-icon svg-icon-primary svg-icon-2x'
          />
          <span className='text-primary'>{informations.price_per_person.toFixed(2)} €</span>
        </div>
      </div>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-5'>
          {/* begin::Table head */}
          <thead>
            <tr>
              <th className='p-0 w-50px'></th>
              <th className='p-0 min-w-100px'></th>
              <th className='p-0 min-w-40px'></th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {ingredients.map((ing, index) => (
              <tr key={index}>
                <td className='p-0 pb-1'>
                  <div className='symbol symbol-50px me-2'>
                    <span className='symbol-label'>
                      <img
                        src={`https://res.cloudinary.com/hv0mwtwcz/${ing.ingredient.main_picture}`}
                        className='h-50 align-self-center'
                        alt={ing.ingredient.name}
                      />
                    </span>
                  </div>
                </td>
                <td className='text-start p-0'>
                  <p className='text-dark mb-1 '>{ing.ingredient.name}</p>
                </td>
                <td className='text-end p-0'>
                  <p className='text-dark mb-1 '>
                    {ing.quantity} {replaceUnitName(ing.unit.name)}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table container */}
      </div>
    </>
  )
}

export {ShowMealRecipe}
