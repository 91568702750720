const API_URL = process.env.REACT_APP_API_URL

/**
 * The function `getAllPlanningsByCustomer` fetches all plannings for a given customer and filters out
 * any plannings with a status of 4.
 * @param {string} customerId - The `customerId` parameter is a string that represents the unique
 * identifier of a customer. It is used to fetch all plannings associated with that customer.
 * @returns an array of plannings filtered by their status.
 */
export const getAllPlanningsByCustomer = async (customerId: string) => {
  const ALL_PLANNING_URL = `${API_URL}/customers/${customerId}/get_all_plannings_light/`
  const response = await fetch(ALL_PLANNING_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data.filter((planning) => planning.status !== 4)
}

/**
 * The function `getWeeklyPlanning` fetches and returns the weekly planning data for a given ID.
 * @param {string} id - The `id` parameter is a string that represents the unique identifier of a
 * weekly planning. It is used to construct the URL for fetching the specific weekly planning data from
 * the API.
 * @returns The function `getWeeklyPlanning` is returning the data fetched from the
 * `WEEKLY_PLANNING_URL`.
 */
export const getWeeklyPlanning = async (id: string) => {
  const WEEKLY_PLANNING_URL = `${API_URL}/public-plannings/${id}/`
  const response = await fetch(WEEKLY_PLANNING_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}

/**
 * The function `getPlanningByDate` retrieves daily planning data by making an API request with the
 * provided `id` and `date`.
 * @param {string} id - The `id` parameter is a string that represents the ID of a planning. It is used
 * to identify a specific planning in the API.
 * @param {string} date - The `date` parameter is a string representing a specific date. It is used to
 * fetch the daily planning for a particular date.
 * @returns the data fetched from the specified URL.
 */
export const getPlanningByDate = async (id: string, date: string) => {
  const DAILY_PLANNING_URL = `${API_URL}/public-plannings/${id}/get_psms_by_date/?date=${date}`
  const response = await fetch(DAILY_PLANNING_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}

/**
 * The function `getAdditionalRecipe` fetches additional recipes based on the provided id and
 * parameters.
 * @param {string} id - The `id` parameter is a string that represents the ID of a public planning. It
 * is used to identify the specific planning for which additional recipes are being requested.
 * @param {string} params - The `params` parameter is a string that represents the type of additional
 * recipes you want to retrieve. It is used to construct the URL for the API request.
 * @returns the data fetched from the API endpoint.
 */
export const getAdditionalRecipe = async (id: string, params: string) => {
  const ADDITIONAL_RECIPES_URL = `${API_URL}/public-plannings/${id}/get_additional_recipes_by_type/?type=${params}`
  const response = await fetch(ADDITIONAL_RECIPES_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}

/**
 * The function `getModifsCounter` makes an asynchronous request to a specified URL and returns the
 * response data as a JSON object.
 * @param {string} id - The `id` parameter is a string that represents the identifier of a public
 * planning. It is used to construct the URL for fetching the modifications counter for that planning.
 * @returns the data obtained from the API response.
 */
export const getModifsCounter = async (id: string) => {
  const MODIFICATIONS_COUNTER_URL = `${API_URL}/public-plannings/${id}/get_modifications_counter/`
  const response = await fetch(MODIFICATIONS_COUNTER_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}

/**
 * The function `getPlanningFile` is an asynchronous function that sends a POST request to a specified
 * API endpoint to retrieve a planning file based on the provided customer ID and planning ID.
 * @param {string} customerId - The `customerId` parameter is a string that represents the ID of the
 * customer for whom the planning file is being requested.
 * @param {string} planningId - The `planningId` parameter is a string that represents the ID of the
 * planning file you want to retrieve.
 * @param recipeId
 * @param fileType
 * @returns the data received from the API response.
 */
export const getPdfFile = async (customerId: string, planningId: string, recipeId: string, fileType: string) => {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    let jsonPayload = JSON.stringify({})
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }

    if (fileType === "planning") {
      jsonPayload = JSON.stringify({
        "pdf_file_type": fileType,
        "planning_id": planningId
      })
    } else if (fileType === "shopping_list") {
      jsonPayload = JSON.stringify({
        "pdf_file_type": fileType,
        "planning_id": planningId
      })
    } else if (fileType === "recipe") {
      jsonPayload = JSON.stringify({
        "pdf_file_type": fileType,
        "recipe_id": recipeId
      })
    }

    const response = await fetch(`${API_URL}/customers/${customerId}/generate_pdf_file/`, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: jsonPayload,
    })

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Erreur lors de l'envoie de la demande", error)
    throw error
  }
}
