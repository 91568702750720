import React, {useEffect, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../../_metronic/layout/core'
import TitleManager from '../../../../_metronic/layout/components/title-manager/TitleManager'
import SidebarRecipe from './SidebarRecipe'
import ContentRecipe from './ContentRecipe'
import {getRecipeDetail, getRecipeReviews} from '../core/_requests'
import {getCustomers} from '../../../modules/profile/core/_requests'
import {LikeRecipe} from '../../../../_metronic/partials/modals/like-recipe/LikeRecipe'
import {brevoTrackEvent} from "../../../modules/brevo/_requests";

interface RecipeData {
  name: string
}

interface LocationState {
  from: string
  planningId: string
}

interface CustomerReview {
  general_rating_int_5: number
}

const RecipePage = () => {
  const {id} = useParams<{id: string}>()
  const location = useLocation() as {state: LocationState}
  const [recipeData, setRecipeData] = useState<RecipeData | null>(null)
  const [customerData, setCustomerData] = useState(null)
  const [customerReview, setCustomerReview] = useState<CustomerReview | null>(null)
  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (id) {
      const fetchRecipeData = async () => {
        try {
          const data = await getRecipeDetail(id)
          setRecipeData(data)
        } catch (error) {
          console.error('Error fetching recipe details:', error)
        }
      }

      fetchRecipeData()
      fetchReviewRecipe()
    }
  }, [id])

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')
        if (customerId) {
          const customerDetail = await getCustomers(customerId)
          setCustomerData(customerDetail)
        }
      } catch (error) {
        console.error('Error fetching customer data:', error)
      }
    }

    fetchCustomerData()
  }, [])

  const fetchReviewRecipe = async () => {
    try {
      const customerId = localStorage.getItem('customer_id')
      if (customerId && id) {
        const customerReview = await getRecipeReviews(id, customerId)
        setCustomerReview(customerReview)
      }
    } catch (error) {
      console.error('Error fetching customer data:', error)
    }
  }

  const handleReviewSubmitted = () => {
    fetchReviewRecipe()
  }

  const {from, planningId} = location.state || {}
  const isFromPlanning = from === 'my-planning'

  const breadcrumbs: Array<PageLink> = [
    {
      title: isFromPlanning ? 'Retour vers mon planning' : 'Retour vers mes fiches recettes',
      path: isFromPlanning ? `/my-planning/${planningId}` : '/my-past-recipes',
      isSeparator: false,
      isActive: false,
    },
  ]


  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_recipe_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  return (
    <>
      <TitleManager title={recipeData ? recipeData.name : 'Sleem.io'} />
      <PageTitle breadcrumbs={breadcrumbs}>
        {recipeData ? recipeData.name : 'Chargement...'}
      </PageTitle>

      <div className='recipe-container d-lg-flex align-items-start row g-5 g-xl-10 mb-5 mb-xl-10'>
        {recipeData && customerData && (
          <>
            <SidebarRecipe
              data={recipeData}
              customerData={customerData}
              customerReview={customerReview}
              rating={rating}
              setRating={setRating}
              generalRating={customerReview?.general_rating_int_5 || 0}
            />
            <ContentRecipe data={recipeData} />
            <LikeRecipe
              rating={rating}
              setRating={setRating}
              onReviewSubmitted={handleReviewSubmitted}
            />
          </>
        )}
      </div>
    </>
  )
}

export default RecipePage
