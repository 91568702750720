/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'

export function HeaderWrapper() {
    const deviceWidth = window.screen.width
    const appSidebarWidth = deviceWidth > 990? 260 : 0
    const planningCreditAvailable = localStorage.getItem("planning_credit_available")? localStorage.getItem("planning_credit_available") : 0
    const isAlertNoPlanningCredit = planningCreditAvailable == 0? "": "d-none"

  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  function reloadPage () {
      const menuVisible = document.getElementById('kt_app_sidebar')!.offsetHeight
      if (menuVisible === 0) {
          window.location.reload()
      }
    }

  return (
    <div id='kt_app_header' className='fixed-top'>

        <div className={`text-center pt-2 pb-2 ${isAlertNoPlanningCredit}`} style={{backgroundColor: "#f1416c", color: "white", paddingLeft: appSidebarWidth}}>
            <span>
                Vous n'avez plus de crédits disponibles !
                <Link to={"/renewal"} style={{color: "white"}}> Cliquez ici pour recharger</Link>
            </span>
        </div>

      <div
        id='kt_app_header_container'
        style={{width: '100%', justifyContent: 'space-between', backgroundColor: "white", paddingTop: "5px", paddingBottom: "5px"}}
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
              <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                  <div className="btn btn-icon btn-active-color-primary w-35px h-35px"
                       id="kt_app_sidebar_mobile_toggle" onClick={reloadPage}>
                      <span className="svg-icon svg-icon-1">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
										<path
                                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                            fill="currentColor"></path>
										<path opacity="0.3"
                                              d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                              fill="currentColor"></path>
									</svg>
								</span>
                  </div>
              </div>
              <div className='d-flex align-items-center d-lg-none ms-n2 me-2 '>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/' className='d-lg-none'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/Logo_sleem_rose_bleu.png')}
                    className='h-30px'
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/Logo_sleem_blanc_rose.png')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/Logo_sleem_rose_rose.png')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/Logo_sleem_blanc_rose.png')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex justify-end align-items-stretch w-full flex-lg-grow-1'
          style={{justifyContent: 'flex-end'}}
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}
