/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {KTIcon} from '../../../helpers'
import {useParams} from 'react-router-dom'
import {addRecipeReview} from '../../../../app/pages/recipes/core/_requests'
import confetti from 'canvas-confetti'
import {Modal} from 'bootstrap'

interface LikeRecipeProps {
  rating: number
  setRating: (rating: number) => void
  onReviewSubmitted?: () => void
}

const LikeRecipe: React.FC<LikeRecipeProps> = ({rating, setRating, onReviewSubmitted}) => {
  const [reviewText, setReviewText] = useState('')
  const {id: recipeId} = useParams()

  const handleRatingChange = (newRating) => {
    setRating(newRating)
  }

  const handleReviewTextChange = (event) => {
    setReviewText(event.target.value)
  }

  const handleSendReview = async () => {
    try {
      const customerId = localStorage.getItem('customer_id')
      if (!customerId || !recipeId) throw new Error('Missing customer or recipe ID')

      await addRecipeReview(customerId, recipeId, rating, reviewText)
      if (onReviewSubmitted) {
        onReviewSubmitted()
      }
      // Déclencher l'animation de confettis
      confetti({
        particleCount: 100,
        spread: 70,
        origin: {y: 0.6},
      })
      setTimeout(() => {
        const modalElement = document.getElementById('kt_modal_like_recipe')
        if (modalElement) {
          const modalInstance = Modal.getInstance(modalElement)
          if (modalInstance) {
            modalInstance.hide()
          }
        }
      }, 1000)
    } catch (error) {
      console.error('Error sending review:', error)
    }
  }

  useEffect(() => {
    setRating(rating)
  }, [rating])

  return (
    <div className='modal fade' id='kt_modal_like_recipe' aria-hidden='true'>
      <div className='modal-dialog mw-450px'>
        <div className='modal-content py-5 px-5'>
          <div className='modal-header p-0 border-0 justify-content-end '>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='d-flex flex-column pt-10 pb-10'>
            <span className='fs-2 text-danger text-center fw-semibold'>
              Donnez-nous votre avis sur la recette
            </span>
            <span id='advert' className='fs-5 text-muted text-center d-none'>
              Votre note à bien été prise en compte !
            </span>
          </div>

          <div className='d-flex flex-column w-100 h-100'>
            <div className='d-flex flex-row w-100 justify-content-center cursor-pointer align-items-center fs-1 gap-3'>
              {[1, 2, 3, 4, 5].map((value) => (
                <i
                  key={value}
                  className={`fas fa-star ${
                    rating >= value ? 'text-danger' : 'text-secondary'
                  } fs-3`}
                  onClick={() => handleRatingChange(value)}
                ></i>
              ))}
            </div>
            <div id='reviewText' className='d-flex flex-column mt-10 w-100'>
              <textarea
                id='reviewInput'
                className='form-control'
                maxLength={255}
                rows={4}
                placeholder='Un commentaire sur cette recette ? (facultatif)'
                onChange={handleReviewTextChange}
              ></textarea>
            </div>

            <div className='w-100 d-flex mt-10'>
              <button id='sendReview' className='w-100 btn btn-danger' onClick={handleSendReview}>
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {LikeRecipe}
