/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../assets/ts/components'
import {KTIcon} from '../../../helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {
  getPlanningSlotMealRecipes,
  getMealRecipe,
  updateMealRecipe,
  updateRefusedIngredients, updateShoppingListAll,
} from './core/_requests'
import {MealData} from './IAppModels'

type ChangeRecipeModalProps = {
  show: boolean
  handleClose: () => void
  mealData: any
  recipeSlotId: string | null
  onRecipeChanged: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ChangeRecipeModal: React.FC<ChangeRecipeModalProps> = ({
  show,
  handleClose,
  mealData,
  recipeSlotId,
  onRecipeChanged,
}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [localMealData, setLocalMealData] = useState<MealData | null>(mealData)
  const [lastRecipeUrl, setLastRecipeUrl] = useState<string | null>(null)
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([])
  const [stepsHistory, setStepsHistory] = useState([1])
  const planningId = window.location.pathname.split('/')[2]
  console.log(planningId)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const nextStep = () => {
    if (stepper.current) {
      setCurrentStep((prev) => {
        const nextStep = prev + 1
        setStepsHistory((prevHistory) => [...prevHistory, nextStep])
        return nextStep
      })
      stepper.current.goNext()
    }
  }

  const prevStep = () => {
    if (stepsHistory.length > 1) {
      const currentStepper = stepper.current!
      setStepsHistory((prevHistory) => {
        const newHistory = prevHistory.slice(0, prevHistory.length - 1)
        const previousStep = newHistory[newHistory.length - 1] || 1
        setCurrentStep(previousStep)
        currentStepper.goTo(previousStep)
        return newHistory
      })
    }
  }

  const goToStep = (stepNumber) => {
    if (stepper.current) {
      stepper.current.goTo(stepNumber)
      setCurrentStep(stepNumber)
      setStepsHistory((prevHistory) => [...prevHistory, stepNumber])
    }
  }

  const cancelStep = () => {
    prevStep()
  }

  const handleCloseWithReset = () => {
    setCurrentStep(1)
    setStepsHistory([1])
    setPresentedRecipes([])
    setAllRecipesPresented(false)
    handleClose()
  }

  const extractIdFromUrl = (url) => {
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 1] || urlParts[urlParts.length - 2]
  }

  const [allRecipesPresented, setAllRecipesPresented] = useState(false)

  const updateMealData = async () => {
    try {
      if (recipeSlotId) {
        const optionalRecipes = await getPlanningSlotMealRecipes(recipeSlotId)
        const newRecipeUrl = selectRandomRecipe(optionalRecipes)

        if (!newRecipeUrl) {
          setAllRecipesPresented(true)
          return
        }

        const recipeId = extractIdFromUrl(newRecipeUrl)

        const newMealData = await getMealRecipe(recipeId)
        setLocalMealData(newMealData)
        console.log('newMealData', newMealData)
      } else {
        console.error('recipeSlotId is null')
      }
    } catch (error) {
      console.error('Failed to update meal data', error)
    }
  }

  useEffect(() => {
    setLocalMealData(mealData)
  }, [mealData])

  const [presentedRecipes, setPresentedRecipes] = useState([])

  const selectRandomRecipe = (recipesData) => {
    let optionalRecipes = [
      recipesData.optional_recipe_1 as never,
      recipesData.optional_recipe_2 as never,
      recipesData.optional_recipe_3 as never,
    ].filter((recipe) => !presentedRecipes.includes(recipe))

    if (optionalRecipes.length === 0) {
      return null
    }

    const randomIndex = Math.floor(Math.random() * optionalRecipes.length)
    const selectedRecipe = optionalRecipes[randomIndex]

    setPresentedRecipes((prev) => [...prev, selectedRecipe])

    return selectedRecipe
  }

  const handleUpdateAndClose = async () => {
    try {
      if (!recipeSlotId || !localMealData?.id) {
        throw new Error('Recipe ID or new Meal ID is not available')
      }
      await updateMealRecipe(recipeSlotId, localMealData.id)
      await updateShoppingListAll(planningId)
      onRecipeChanged()
      handleCloseWithReset()
      window.loadModificationsCounter?.()
    } catch (error) {
      console.error(error)
    }
  }

  const onIngredientSelectionChange = (ingredients: string[]) => {
    setSelectedIngredients(ingredients)
  }

  const handleContinueClick = async () => {
    if (currentStep === 3) {
      await updateMealData()
      const customerId = localStorage.getItem('customer_id')
      const csrfToken = localStorage.getItem('csrftoken')
      if (!customerId) {
        console.error('Customer ID is missing')
        return
      }

      if (!csrfToken) {
        console.error('CsrfToken is missing')
        return
      }

      for (const ingredientId of selectedIngredients) {
        try {
          await updateRefusedIngredients(customerId, ingredientId)
        } catch (error) {
          console.error('Failed to update refused ingredients', error)
        }
      }
    }
    nextStep()
  }

  const cancelAndGoToStep1 = async () => {
    goToStep(1)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px'
      show={show}
      onHide={handleCloseWithReset}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-body p-0 bg-modal rounded'>
        {/* begin::Close */}
        <div
          style={{textAlign: 'right'}}
          className='btn btn-sm btn-color-white w-full justify-end'
          onClick={handleCloseWithReset}
        >
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-none d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'></div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'></div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'></div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'></div>
              {/* end::Step 4*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid pb-5 px-10 pt-0'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <Step1 mealData={localMealData || mealData} />
              <Step2
                mealData={localMealData || mealData}
                goToStep={goToStep}
                updateMealData={updateMealData}
              />

              <Step3
                mealData={localMealData || mealData}
                onIngredientSelectionChange={onIngredientSelectionChange}
              />
              <Step4
                mealData={localMealData || mealData}
                onCancel={cancelAndGoToStep1}
                allRecipesPresented={allRecipesPresented}
              />

              {/*begin::Actions */}
              {!allRecipesPresented && (
                <div className='d-flex flex-stack pt-3 justify-center'>
                  {currentStep === 1 && (
                    <>
                      <button
                        type='button'
                        className='btn btn-lg btn-color-white bg-red me-4'
                        onClick={handleCloseWithReset}
                      >
                        Non <KTIcon iconName='cross' className='fs-3 ms-1 me-0' />
                      </button>
                      <button
                        type='button'
                        className='btn btn-lg btn-success'
                        data-kt-stepper-action='next'
                        onClick={nextStep}
                      >
                        Oui <KTIcon iconName='check' className='fs-3 ms-1 me-0' />
                      </button>
                    </>
                  )}

                  {currentStep === 2 && (
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={cancelStep}
                    >
                      <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Annuler
                    </button>
                  )}

                  {currentStep === 3 && (
                    <>
                      <button
                        type='button'
                        className='btn btn-lg btn-color-white bg-red me-4'
                        onClick={cancelStep}
                      >
                        Non <KTIcon iconName='cross' className='fs-3 ms-1 me-0' />
                      </button>
                      <button
                        type='button'
                        className='btn btn-lg btn-success'
                        data-kt-stepper-action='next'
                        onClick={handleContinueClick}
                      >
                        Continuer <KTIcon iconName='check' className='fs-3 ms-1 me-0' />
                      </button>
                    </>
                  )}

                  {currentStep === 4 && (
                    <>
                      <button
                        type='button'
                        className='btn btn-lg btn-color-white bg-red me-4'
                        onClick={cancelStep}
                      >
                        Non <KTIcon iconName='cross' className='fs-3 ms-1 me-0' />
                      </button>
                      <button
                        type='button'
                        className='btn btn-lg btn-success'
                        data-kt-stepper-action='submit'
                        onClick={handleUpdateAndClose}
                      >
                        Oui <KTIcon iconName='check' className='fs-3 ms-2 me-0' />
                      </button>
                    </>
                  )}
                </div>
              )}
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ChangeRecipeModal}
