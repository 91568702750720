import React, {useState} from 'react'

const URL_IMG_RECIPES = process.env.REACT_APP_URL_IMG_RECIPES

const SidebarRecipe = ({data, customerData, customerReview, rating, setRating, generalRating}) => {
  const [numberOfPeople, setNumberOfPeople] = useState(customerData.household_people_number || 1)
  const [hoveredStar, setHoveredStar] = useState(0)

  const currentRating = hoveredStar > 0 ? hoveredStar : generalRating

  const handlePeopleChange = (change) => {
    const newNumber = numberOfPeople + change
    if (newNumber > 0) {
      setNumberOfPeople(newNumber)
    }
  }

  const getFormattedQuantity = (quantity) => {
    const adjustedQuantity = quantity * numberOfPeople
    let formattedQuantity = adjustedQuantity

    switch (adjustedQuantity) {
      default:
        if (!Number.isInteger(adjustedQuantity)) {
          formattedQuantity = parseFloat(adjustedQuantity.toFixed(2))
        }
        return formattedQuantity
    }
  }

  const handleStarClick = (newRating) => {
    setRating(newRating)
  }

  return (
    <>
      <div className='d-flex flex-column justify-content-center align-items-center col-4'>
        <div className='card p-0 card-flush overflow-hidden h-lg-auto'>
          <div
            className='w-100'
            style={{
              backgroundImage: `url('${URL_IMG_RECIPES}${data.main_picture}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '300px',
            }}
          ></div>

          {/* <!--begin::Card body--> */}

          <div className='card-body d-flex flex-column align-items-center justify-content-center'>
            <h1 className='title-mobile d-none'>{data.name}</h1>
            <div
              style={{marginTop: '0.5em', marginBottom: '0.5em'}}
              id='likeRecipe'
              className='d-flex w-100 align-items-center justify-content-start cursor-pointer'
            >
              <label style={{paddingRight: '1em'}}>Votre avis :</label>
              {[1, 2, 3, 4, 5].map((value) => (
                <i
                  key={value}
                  id='star'
                  data-value={value}
                  className={`fa fa-star fs-3 ${
                    value <= currentRating ? ' text-danger' : 'text-secondary'
                  }`}
                  onMouseEnter={() => setHoveredStar(value)}
                  onMouseLeave={() => setHoveredStar(0)}
                  onClick={() => handleStarClick(value)}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_like_recipe'
                ></i>
              ))}
            </div>
            {/* Start preparation mobile */}
            <div
              className='preparation-mobile d-none d-flex justify-content-center align-items-center gap-2 flex-wrap'
              style={{marginBottom: '25px', marginTop: '25px'}}
            >
              <span
                className='badge fs-3 gap-1 bg-danger bg-opacity-10 badge-danger'
                style={{color: 'rgba(244,15,99, 1)'}}
              >
                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24'></rect>
                      <path
                        d='M5.28248558,14.4748737 L14.4748737,5.28248558 C15.0606602,4.69669914 16.0104076,4.69669914 16.5961941,5.28248558 L18.7175144,7.40380592 C19.3033009,7.98959236 19.3033009,8.93933983 18.7175144,9.52512627 L9.52512627,18.7175144 C8.93933983,19.3033009 7.98959236,19.3033009 7.40380592,18.7175144 L5.28248558,16.5961941 C4.69669914,16.0104076 4.69669914,15.0606602 5.28248558,14.4748737 Z'
                        fill='rgb(244,15,99)'
                      ></path>
                      <path
                        d='M3.51471863,18.363961 L4.22182541,17.6568542 C4.41708755,17.4615921 4.73367004,17.4615921 4.92893219,17.6568542 L6.34314575,19.0710678 C6.5384079,19.26633 6.5384079,19.5829124 6.34314575,19.7781746 L5.63603897,20.4852814 C5.05025253,21.0710678 4.10050506,21.0710678 3.51471863,20.4852814 C2.92893219,19.8994949 2.92893219,18.9497475 3.51471863,18.363961 Z M18.363961,3.51471863 C18.9497475,2.92893219 19.8994949,2.92893219 20.4852814,3.51471863 C21.0710678,4.10050506 21.0710678,5.05025253 20.4852814,5.63603897 L19.7781746,6.34314575 C19.5829124,6.5384079 19.26633,6.5384079 19.0710678,6.34314575 L17.6568542,4.92893219 C17.4615921,4.73367004 17.4615921,4.41708755 17.6568542,4.22182541 L18.363961,3.51471863 Z'
                        fill='rgb(244,15,99)'
                        opacity='0.3'
                      ></path>
                    </g>
                  </svg>
                  {/* <!--end::Svg Icon--> */}
                </span>
                {data.preparation_time_min} min
              </span>

              <span
                className='badge fs-3 gap-1 bg-danger bg-opacity-10 badge-danger'
                style={{color: 'rgba(244,15,99, 1)'}}
              >
                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24'></rect>
                      <path
                        d='M7.03587629,17.2664036 L2.54094808,14.2345363 C1.62521847,13.6168689 1.38359132,12.373805 2.00125875,11.4580753 C2.61892617,10.5423457 3.86199008,10.3007186 4.7777197,10.918386 L7,12.417333 L7,8 C7,4.6862915 9.6862915,2 13,2 C16.3137085,2 19,4.6862915 19,8 L19,17 C19,17.5522847 18.5522847,18 18,18 L8,18 C7.53996718,18 7.15248755,17.6893628 7.03587629,17.2664036 Z'
                        fill='rgb(244,15,99)'
                      ></path>
                      <rect
                        fill='rgb(244,15,99)'
                        opacity='0.3'
                        x='6'
                        y='20'
                        width='14'
                        height='2'
                        rx='1'
                      ></rect>
                    </g>
                  </svg>
                  {/* <!--end::Svg Icon--> */}
                </span>
                {data.cooking_time_min} min
              </span>

              <span
                className='badge fs-3 gap-1 bg-danger bg-opacity-10 badge-danger'
                style={{color: 'rgba(244,15,99, 1)'}}
              >
                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24'></rect>
                      <rect
                        fill='rgb(244,15,99)'
                        opacity='0.2'
                        x='7'
                        y='4'
                        width='10'
                        height='4'
                      ></rect>
                      <path
                        d='M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z'
                        fill='rgb(244,15,99)'
                      ></path>
                    </g>
                  </svg>
                  {/* <!--end::Svg Icon--> */}
                </span>
                {Math.round(data.kcal_per_recipe_per_person)} Kcal
              </span>

              <span
                className='badge fs-3 gap-1 bg-danger bg-opacity-10 badge-danger'
                style={{color: 'rgba(244,15,99, 1)'}}
              >
                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24px'
                    height='24px'
                    viewBox='0 0 24 24'
                    version='1.1'
                  >
                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                      <rect x='0' y='0' width='24' height='24'></rect>
                      <path
                        d='M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z'
                        fill='rgb(244,15,99)'
                        opacity='0.4'
                      ></path>
                      <path
                        d='M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z'
                        fill='rgb(244,15,99)'
                      ></path>
                    </g>
                  </svg>
                  {/* <!--end::Svg Icon--> */}
                </span>
                {Number(data.price_per_person).toFixed(2)} €
              </span>
            </div>
            {/* End preparation mobile */}
            <div className='d-flex gap-2 w-100 flex-row justify-content-between mt-4 mb-1'>
              <div className='d-flex align-items-center'>
                <span className='h2 fw-bold'>Nombre de pers.</span>
              </div>
              <div className='d-flex align-items-center'>
                <button
                  onClick={() => handlePeopleChange(-1)}
                  style={{
                    border: '1px solid rgb(235, 15, 99)',
                    backgroundColor: 'white',
                    borderRadius: '100%',
                    width: '25px',
                    height: '25px',
                    padding: '0px',
                    color: "black",
                  }}
                >
                  -
                </button>
                <span id='person' className='mx-4'>
                  {numberOfPeople}
                </span>
                <button
                  onClick={() => handlePeopleChange(1)}
                  style={{
                    border: '1px solid rgb(235, 15, 99)',
                    backgroundColor: 'white',
                    borderRadius: '100%',
                    width: '25px',
                    height: '25px',
                    padding: '0px',
                    color: "black",
                  }}
                >
                  +
                </button>
              </div>
            </div>
            {data.recipe_ingredients.map((ingredient, index) => (
              <div key={index} className='d-flex w-100 flex-row justify-content-between mt-2'>
                <div className='d-flex align-items-center my-1'>
                  <div
                    style={{
                      backgroundImage: `url('${URL_IMG_RECIPES}${ingredient.ingredient.main_picture}')`,
                      width: '35px',
                      height: '35px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    className='mx-3'
                    style={{
                      color: ingredient.is_optional ? '#619331' : 'inherit',
                      fontStyle: ingredient.is_optional ? 'italic' : 'inherit',
                    }}
                  >
                    <span>{ingredient.ingredient.name}</span>
                    {ingredient.is_optional && (
                      <>
                        <br />
                        <span style={{fontSize: '0.7em'}}>Ingrédient optionnel</span>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className='d-flex align-items-center my-1 gap-1'
                  style={{
                    color: ingredient.is_optional ? '#619331' : 'inherit',
                    fontStyle: ingredient.is_optional ? 'italic' : 'inherit',
                  }}
                >
                  <span>{getFormattedQuantity(ingredient.quantity)}</span>
                  <span>{ingredient.unit.front_displayable_name || ingredient.unit.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SidebarRecipe
