import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {AllPlanningWrapper} from '../pages/planning/AllPlanningWrapper'
import {RecipesWrapper} from '../pages/recipes/RecipesWrapper'
import {AccountWrapper} from '../pages/account/AccountWrapper'
import {CourseList} from '../pages/course-list/CourseList'
import {Planning} from '../pages/planning/components/Planning'
import RecipePage from '../pages/recipes/components/RecipePage'
import {FaqList} from "../pages/faq/FaqWrapper";
import {AdviceList} from "../pages/advice/AdviceWrapper";
import {RenewalWrapper} from "../pages/renewal/RenewalWrapper";
import {AiAssistantPage} from "../pages/ai-assistant/AiAssistantPage";


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Planning after success login */}
        <Route path='auth/*' element={<Navigate to='/my-planning' />} />
        {/* Redirect from index to /my-planning */}
        <Route path='/' element={<Navigate to='/my-planning' replace />} />
        {/* Pages */}
        <Route path='my-past-recipes' element={<RecipesWrapper />} />
        <Route path='recipe/:id' element={<RecipePage />} />
        <Route path='my-advice' element={<AdviceList />} />
        <Route path='faq' element={<FaqList />} />
        <Route path='my-planning/*' element={<AllPlanningWrapper />} />
        <Route path='my-planning/:id' element={<Planning />} />
        <Route path='my-account' element={<AccountWrapper />} />
        <Route path='shopping-list/:id' element={<CourseList />} />
        <Route path='renewal' element={<RenewalWrapper />} />
        <Route path='assistant' element={<AiAssistantPage />} />
        <Route index element={<Navigate to='my-profile' />} />
        {/* Lazy Modules */}
        <Route
          path='my-profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Other redirects */}
        <Route path='/signin' element={<Navigate to='/' />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
