/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import React, {useState} from 'react'
import SearchBar from './components/SearchBar'
import NavTabsRecipes from './components/NavTabsRecipes'
import {RecipeList} from './components/RecipeList'
import {brevoTrackEvent} from "../../modules/brevo/_requests";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";

const RecipesPage: FC = () => {
  const [activeTab, setActiveTab] = useState('mains')
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_all_recipes_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      setIsLoading(false)
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  if (isLoading) return <div>Chargement...</div>

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        <NavTabsRecipes activeTab={activeTab} setActiveTab={setActiveTab} />
        <RecipeList activeTab={activeTab} searchValue={searchValue} />
      </div>
    </>
  )
}

const RecipesWrapper: FC = () => {
  return (
    <>
      <TitleManager title='Mes recettes - Sleem' />
      <RecipesPage />
    </>
  )
}

export {RecipesWrapper}
