import {Link} from "react-router-dom";
import React from "react";

const Advantages = ({customer}) => {

    return (
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0" data-bs-toggle="collapse"
             data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Mes avantages</h3>
          </div>
        </div>
        <div id="kt_account_settings_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row d-flex justify-content-left align-items-center">
              <div className="col-12">
                <div className="">
                    <h3>💕 Parrainez vos proches :</h3>
                    <p>Bénéficiez de <b>2 semaines gratuites</b> en parrainant un proche. Votre filleul profitera aussi du même avantage.</p>
                    <p><b>Envoyez-nous son nom ou son email</b> à <a href={"mailto:hello@sleem.io"} target={"_blank"} className="text-decoration-underline">hello@sleem.io</a>.
                      Le parrainage est validé dès que vous et votre filleul aurez souscrit à une offre payante.</p>
                    <div className="d-flex flex-row-reverse mt-1">
                        <div>
                            <a href={'mailto:hello@sleem.io'} target={"_blank"} className="btn btn-danger btn-active-light-danger">
                                Je parraine
                            </a>
                        </div>
                    </div>

                    <h3 className={'mt-10'}>⭐️ Déposez un avis Google :</h3>
                    <p>Bénéficiez d’<b>1 semaine gratuite</b> en nous laissant un avis Google.</p>
                    <p>
                        Ça nous aidera à nous faire connaître encore plus et ce sera aussi utile à de nouveaux utilisateurs.
                        Pour donner votre avis, <a target={"_blank"} href={"https://www.google.com/search?q=sleem+google&sca_esv=558130159&rlz=1C1GTPM_enFR1061FR1061&ei=0H7fZLCDHYKtkdUP8L-DqAU&ved=0ahUKEwiwq6KmtOaAAxWCVqQEHfDfAFUQ4dUDCA8&uact=5&oq=sleem+google&gs_lp=Egxnd3Mtd2l6LXNlcnAaAhgCIgxzbGVlbSBnb29nbGUyChAAGBYYHhgPGApItAxQAFigC3AAeACQAQCYAb0BoAHbB6oBBDEwLjK4AQPIAQD4AQHCAgcQABiKBRhDwgILEAAYgAQYsQMYgwHCAggQABiABBixA8ICERAuGIAEGLEDGIMBGMcBGNEDwgILEC4YgAQYxwEY0QPCAgsQLhiKBRixAxiDAcICCBAuGIAEGLEDwgIHEC4YigUYQ8ICChAAGIoFGLEDGEPCAhEQLhiABBixAxiDARjHARivAcICFBAuGIAEGLEDGIsDGKgDGKQDGJEFwgIXEC4YgAQYsQMYgwEYiwMYqAMYpAMYkQXCAgUQLhiABMICFBAuGIAEGLEDGIsDGKgDGJEFGKQDwgIXEC4YgAQYsQMYgwEYiwMYqAMYkQUYpAPCAgUQABiABMICChAuGIAEGLEDGArCAgcQABiABBgKwgINEAAYgAQYsQMYgwEYCsICCRAAGBMYgAQYCsICChAAGBYYHhgTGArCAgwQABgWGB4YDxgTGArCAg8QABgWGB4YDxjxBBgTGArCAggQABgWGB4YE8ICBxAAGA0YgATiAwQYACBBiAYB&sclient=gws-wiz-serp#lrd=0x5ffa5fd6b7bade5:0x3a2adc1f540898ce,1,,,,"}>
                        <b>il suffit de cliquer ici.</b></a>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Advantages
