import {FC, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../../_metronic/layout/core'
import {PlanningDay} from './SubComponents/PlanningDay'
import {PlanningWeek} from './SubComponents/PlanningWeek'
import {AdditionalRecipes} from './SubComponents/AdditionalRecipes'
import {usePlanningDates} from '../../../../_metronic/helpers'
import TitleManager from '../../../../_metronic/layout/components/title-manager/TitleManager'
import {brevoTrackEvent} from "../../../modules/brevo/_requests";

const planningBreadCrumbs: Array<PageLink> = [
  {
    title: 'Retour vers mes plannings',
    path: '/my-planning',
    isSeparator: false,
    isActive: false,
  },
]

/**
 * The Planning component renders a page with a title, a daily planning section, a weekly planning
 * section, and additional recipes section.
 */
const Planning: FC = () => {
  const {id} = useParams()
  const {startDate, endDate, formattedDate} = usePlanningDates(id)

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_planning_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  return (
    <>
      <TitleManager title={`Mon planning ${formattedDate} - Sleem`} />
      <PageTitle breadcrumbs={planningBreadCrumbs}>
        {formattedDate ? `Mon planning ${formattedDate}` : 'Mon planning'}
      </PageTitle>
      <PlanningDay className='card-xl-stretch mb-8' />
      <PlanningWeek className='card-xl-stretch mb-8' />
      <AdditionalRecipes className='card-xl-stretch' />
    </>
  )
}

export {Planning}
