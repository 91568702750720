import React, {FC, useEffect, useState} from 'react'
import Pagination from '../components/Pagination'
import {getRecipes} from '../core/_requests'
import {Link} from 'react-router-dom'

const URL_IMG_RECIPES = process.env.REACT_APP_URL_IMG_RECIPES
interface RecipeListProps {
  activeTab: string
  searchValue: string
}

const RecipeList: FC<RecipeListProps> = ({activeTab, searchValue}) => {
  const [recipes, setRecipes] = useState({
    breakfasts: [],
    starters: [],
    mains: [],
    desserts: [],
    bonuses: [],
  })
  const [currentPage, setCurrentPage] = useState(1)
  const recipesPerPage = 10
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')
        if (!customerId) {
          throw new Error('Customer ID not found')
        }
        const data = await getRecipes(customerId)
        setRecipes(data)

        setIsLoading(false)

      } catch (error) {
        console.error('Error fetching recipes:', error)
      }
    }

    fetchRecipes()
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [activeTab])

  const activeRecipes = recipes[activeTab] || []
  const filteredRecipes = activeRecipes.filter((recipe) =>
    recipe.name.toLowerCase().includes(searchValue.toLowerCase())
  )
  const indexOfLastRecipe = currentPage * recipesPerPage
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage
  const currentRecipes = filteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  if (searchValue && currentPage > Math.ceil(filteredRecipes.length / 10) && indexOfFirstRecipe) {
    setCurrentPage(1)
  }

  if (isLoading) return <div>Chargement...</div>

  return (
    <div className='tab-content'>
      <div className='tab-pane fade show active' role='tabpanel'>
        <div className='card card-flush overflow-hidden h-lg-100'>
          <div className='card-body px-5 px-lg-8'>
            <div className='table-responsive'>
              <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                <thead>
                  <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                    <th className='min-w-100px text-start'></th>
                    <th className='min-w-100px text-center d-none d-lg-none'>Prix</th>
                    <th className='min-w-100px text-center d-none d-lg-none'>Préparation</th>
                    <th className='min-w-100px text-center d-none d-lg-none'>Cuisson</th>
                    <th className='min-w-100px text-center d-none d-lg-none'>Calories</th>
                    <th className='text-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecipes.length > 0 ? (
                    currentRecipes.map((recipe) => (
                      <tr key={recipe.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-3'>
                              <div
                                className='symbol w-12 h-12 me-3'
                                style={{
                                  backgroundImage: `url(${URL_IMG_RECIPES}${recipe.main_picture})`,
                                  backgroundSize: 'cover',
                                  width: '50px',
                                  height: '50px',
                                }}
                              ></div>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                className='text-gray-800 fw-bold text-hover-danger mb-1 fs-6'
                                to={`/recipe/${recipe.id}`}
                              >
                                {recipe.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td className='text-center d-none d-lg-none'>
                          <span className='text-gray-600 fw-bold fs-6'>
                            {Number(recipe.price_per_person).toFixed(2)} €
                          </span>
                        </td>
                        <td className='text-center d-none d-lg-none'>
                          <span className='text-gray-600 fw-bold fs-6'>
                            {recipe.preparation_time_min} min
                          </span>
                        </td>
                        <td className='text-center d-none d-lg-none'>
                          <span className='text-gray-600 fw-bold fs-6'>
                            {recipe.cooking_time_min} min
                          </span>
                        </td>
                        <td className='text-center d-none d-lg-none'>
                          <span className='text-gray-600 fw-bold fs-6'>
                            {Math.round(recipe.kcal_per_recipe_per_person)} Kcal
                          </span>
                        </td>
                        <td className='text-end'>
                          <Link
                            className='btn btn-sm btn-icon btn-bg-danger btn-active-color-primary w-30px h-30px'
                            to={`/recipe/${recipe.id}`}
                          >
                            <i className='fas fa-arrow-right text-white'></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className='text-center'>
                          <p className='text-gray-600'>Aucune recette disponible</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={recipesPerPage}
              totalItems={filteredRecipes.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {RecipeList}
