import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getWeeklyPlanning, getPlanningByDate} from '../../core/_requests'

const URL_IMG_RECIPES = process.env.REACT_APP_URL_IMG_RECIPES
const DEFAULT_IMAGE = '../public/media/images/plat-default.jpeg'

type Props = {
  className: string
}

const PlanningWeek: React.FC<Props> = ({className}) => {
  const [dates, setDates] = useState<string[]>([])
  const [dailyPlannings, setDailyPlannings] = useState<{[date: string]: any[]}>({})
  const {id} = useParams<{id: string}>()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (typeof id === 'undefined') {
          console.error('id is undefined')
          return
        }

        const response = await getWeeklyPlanning(id)
        if (!Array.isArray(response.planning_slot_meals)) {
          throw new Error('planning_slot_meals is not an array')
        }

        const planningSlotMeals = response.planning_slot_meals
        const uniqueDates = Array.from(
          new Set(planningSlotMeals.map((slot) => slot.date as string))
        ) as string[]
        setDates(uniqueDates)

        const allPlannings = {}
        for (const date of uniqueDates) {
          allPlannings[date] = await getPlanningByDate(id, date)
        }
        setDailyPlannings(allPlannings)
      } catch (error) {
        console.error('Error fetching weekly planning', error)
      }
    }

    fetchData()
  }, [id])

  /**
   * The `truncateString` function takes a string and a number as input and returns a truncated version
   * of the string with ellipsis (...) if it exceeds the specified length.
   * @param {string} str - The `str` parameter is a string that represents the input string that we
   * want to truncate.
   * @param {number} num - The `num` parameter in the `truncateString` function represents the maximum
   * length of the string that you want to truncate.
   * @returns The function `truncateString` returns a truncated version of the input string `str`. If
   * the length of `str` is less than or equal to the input number `num`, it returns the original
   * string `str`. Otherwise, it returns a substring of `str` from index 0 to `num`, concatenated with
   * '...'.
   */
  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <div className={`card ${className} d-none`} id='planning-weekly-view'>
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='p-0 min-w-0px'></th>
                {dates.map((date) => {
                  const dateObj = new Date(date)
                  const weekday = dateObj.toLocaleDateString('fr-FR', {weekday: 'long'})
                  const day = dateObj.toLocaleDateString('fr-FR', {day: 'numeric'})

                  return (
                    <th className='p-3 min-w-100px text-center fw-bold' key={date}>
                      <span className='weekday text-muted'>{weekday}</span>
                      <br />
                      <span className='day'>{day}</span>
                    </th>
                  )
                })}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Array.from(
                {
                  length: Math.max(
                    ...Object.values(dailyPlannings).map((planning) => planning.length)
                  ),
                },
                (_, idx) => idx
              ).map((index, arrIndex) => (
                <tr key={index}>
                  <td className='vertical-text fw-bold text-gray-400'>
                    {arrIndex === 0 ? 'Midi' : 'Soir'}
                  </td>
                  {dates.map((date) => (
                    <td key={date}>
                      {dailyPlannings[date] &&
                        dailyPlannings[date][index] &&
                        dailyPlannings[date][index].planning_slot_meal_recipes.map(
                          (recipeSlot, recipeSlotIndex) => {
                            if (!recipeSlot || !recipeSlot.recipe) {
                              console.error('RecipeSlot or RecipeSlot.recipe is null or undefined')
                              return null
                            }

                            return (
                              <div key={`${index}-${recipeSlotIndex}`} className='mb-3'>
                                <div className='d-flex align-items-center flex-column mb-1'>
                                  <div className='symbol symbol-45px me-2'>
                                    <div
                                      className='symbol'
                                      style={{
                                        backgroundImage: `url(${
                                          recipeSlot.recipe.main_picture
                                            ? URL_IMG_RECIPES + recipeSlot.recipe.main_picture
                                            : DEFAULT_IMAGE
                                        })`,
                                        backgroundSize: 'cover',
                                        width: '60px',
                                        height: '60px',
                                      }}
                                    ></div>
                                  </div>
                                  <p className='text-gray-800 fw-bold mb-1 fs-6 text-center'>
                                    {truncateString(recipeSlot.recipe.name, 15)}
                                  </p>
                                </div>
                              </div>
                            )
                          }
                        )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {PlanningWeek}
