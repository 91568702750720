const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/customers/login/`
export const REQUEST_PASSWORD_URL = `${API_URL}/customers/reset_password/`
export const CHANGE_PASSWORD_URL = `${API_URL}/customers/change_password/`

/**
 * The login function sends a POST request to a login URL with the provided username and password, and
 * stores the customer ID, first name, and last name in the local storage if the login is successful.
 * @param {string} username - The `username` parameter is a string that represents the email of the
 * user trying to login. It is used to identify the user during the login process.
 * @param {string} password - The `password` parameter is a string that represents the user's password.
 * It is used to authenticate the user during the login process.
 * @returns the `data` object.
 */
export async function login(username: string, password: string) {
  const csrftoken = localStorage.getItem('csrftoken')
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  }
  if (csrftoken !== null) {
    headers['X-CSRFToken'] = csrftoken
  }
  const response = await fetch(LOGIN_URL, {
    method: 'POST',
    credentials: 'include',
    headers: headers,
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  })

  if (!response.ok) {
    throw new Error(`Failed to login: ${response.statusText}`)
  }

  const data = await response.json()
  console.log(data)

  if (data.customer_id) {
    localStorage.setItem('customer_id', data.customer_id)
    localStorage.setItem('first_name', data.first_name)
    localStorage.setItem('last_name', data.last_name)
    localStorage.setItem('email', data.email)
    localStorage.setItem('csrftoken', data.csrf)
    localStorage.setItem('sessionid', data.sessionid)
    localStorage.setItem('planning_credit_available', data.planning_credit_available)
  }

  return data
}

/**
 * The function `requestPassword` sends a POST request to a specified URL with the email as a parameter
 * and returns the response as JSON.
 * @param {string} username - The `email` parameter is a string that represents the email address of the
 * user for whom the password is being requested.
 * @returns the JSON response from the server.
 */
export async function requestPassword(username: string) {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(REQUEST_PASSWORD_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        username: username,
      }),
    })
    if (!response.ok) {
      throw new Error('Erreur lors de la requête')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erreur lors de la requête', error)
    throw error
  }
}

/**
 * The function `changePassword` sends a POST request to a specified URL with a token and a new
 * password, and returns the response as JSON.
 * @param {string} password - The `password` parameter is a string that represents the new password
 * that the user wants to set.
 * @param {string} token - The `token` parameter is a string that represents the reset password token.
 * This token is typically generated when a user requests to reset their password and is used to verify
 * the user's identity and authorize the password change.
 * @returns the result of calling `response.json()`, which is a promise that resolves to the parsed
 * JSON data from the response.
 */
export async function changePassword(password: string, token: string) {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(CHANGE_PASSWORD_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        rp_token: token,
        new_password: password,
      }),
    })
    if (!response.ok) {
      throw new Error('Erreur lors du changement de mot de passe')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erreur lors du changement de mot de passe', error)
    throw error
  }
}

/**
 * The above function is an asynchronous function that sends a logout request to a specified URL and
 * returns the response data.
 * @returns the data received from the API response.
 */
export const logout = async () => {
  const LOGOUT_URL = `${API_URL}/customers/logout/`
  try {
    const response = await fetch(LOGOUT_URL, {
      credentials: 'include',
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Logout failed:', error)
    throw error
  }
}
