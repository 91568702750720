/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid app-blank bgi-size-cover bgi-position-center bgi-no-repeat'
      style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.webp')})`}}
    >
      {/* begin::Aside */}
      <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
        {/* begin::Content */}
        <div className='d-flex flex-column py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-5'>
            <img
              alt='Logo Sleem'
              src={toAbsoluteUrl('/media/logos/Logo_sleem_blanc_rose.png')}
              className='h-75px'
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Text */}
          <h2 className='text-white fw-normal m-0'>
            Sleem est l’assistant qui vous crée des plannings de menus 100% personnalisés et qui
            vous aide à faire vos courses
          </h2>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='form-auth w-lg-550px p-10 p-lg-20 bg-white rounded-lg'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
