import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";
import {PageTitle} from "../../../_metronic/layout/core";
import {getAdvicesList} from "./_requests";
import { Parser } from 'html-to-react';
import {brevoTrackEvent} from "../../modules/brevo/_requests";

const AdviceList: FC = () => {
  const [faqs, setAdvices] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await getAdvicesList()
        setAdvices(items.results)

        setIsLoading(false)

      } catch (error) {
        console.error('Error fetching advices list', error)
        navigate('/error/404')
      }
    }
    fetchData()
  }, [navigate])

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_advice_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);


  if (isLoading) return <div>Chargement...</div>


  const listItems = faqs.map((category, index) => (
            <div key={category.id} className="card mb-5 mb-xl-8">
                <div className="card-header border-0">
                    <h3 className="card-title fw-bold text-dark">{category.name}</h3>
                </div>

                {category.advices.map((advice, index) => (
                    <div key={advice.id} className="card-body pt-0">
                        <div className={`d-flex align-items-center bg-${advice.color} bg-opacity-20 rounded p-5 mb-7`}>
                            <span className={`svg-icon svg-icon-${advice.color} svg-icon-1 me-5`}>
                                <a href={advice.pdf_file}>
                                    {Parser().parse(advice.svg_icon)}
                                </a>
                            </span>

                            <div className="flex-grow-1 me-2">
                                <a href={advice.pdf_file}>
                                    <span className="fw-bold text-gray-800 fs-6">{advice.title}</span>
                                    <span className="text-muted fw-semibold d-block">{advice.subtitle}</span>
                                </a>
                            </div>

                            <a href={advice.pdf_file} className={`btn btn-sm btn-icon btn-bg-${advice.color} 
                            btn-active-color-${advice.color} w-30px h-30px`}>
                                <span className={`svg-icon svg-icon-5 svg-icon-${advice.color}`}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                                              fill="white">
                                        </path>
                                        <path opacity="0.4" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                                              fill="white">
                                        </path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
  ))

    if (isLoading) return <div>Chargement...</div>

  return (
    <>
      <TitleManager title='Mes conseils - Sleem' />
      <PageTitle>Mes Conseils</PageTitle>
      <div className={"col-12"}>
        {listItems}
      </div>
    </>
  )
}

export {AdviceList}
