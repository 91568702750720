import clsx from 'clsx'
import {ToolbarType, useLayout} from '../../core'
import {ToolbarPlanning} from '../../../../app/pages/planning/components/SubComponents/ToolbarPlanning'
import {ToolbarAllPlanning} from '../../../../app/pages/planning/components/SubComponents/ToolbarAllPlanning'
import {ToolbarRecipe} from "../../../../app/pages/recipes/components/ToolbarRecipe";
import {PageTitleWrapper} from './page-title'
import {useLocation} from 'react-router-dom'
import {ToolbarReloadAssistant} from "../../../../app/pages/ai-assistant/components/ToolbarReloadAssistant";
import {ToolbarCourseList} from "../../../../app/pages/course-list/components/ToolbarCourseList";


const ToolbarWrapper = () => {
  const {config, classes} = useLayout()
  const location = useLocation()

  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  return (
    <div
      id='kt_app_toolbar'
      className={clsx('app-toolbar', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
    >
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container items-baseline',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {location.pathname.match(/^\/my-planning$/) && <ToolbarAllPlanning />}
        {isPageTitleVisible && <PageTitleWrapper />}
        {location.pathname.match(/^\/my-planning\/.+$/) && <ToolbarPlanning />}
        {location.pathname.match(/^\/recipe\/.+$/) && < ToolbarRecipe/>}
        {location.pathname.match(/^\/shopping-list\/.+$/) && <ToolbarCourseList />}
        {location.pathname.match(/^\/assistant$/) && <ToolbarReloadAssistant />}
      </div>
    </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export {ToolbarWrapper}
