const API_URL = process.env.REACT_APP_API_URL

/**
 * The function `getMealRecipe` is an asynchronous function that fetches a meal recipe from a specified
 * URL and returns the data.
 * @param {string} idRecipe - The `idRecipe` parameter is a string that represents the unique
 * identifier of a meal recipe. It is used to construct the URL for fetching the recipe details from
 * the API.
 * @returns the data fetched from the specified URL.
 */
export const getMealRecipe = async (idRecipe: string) => {
  const MEAL_RECIPE_URL = `${API_URL}/public-recipes/${idRecipe}/`
  const response = await fetch(MEAL_RECIPE_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}

/**
 * The function `getPlanningSlotMealRecipes` fetches data from a specific URL and returns the response
 * data as JSON.
 * @param {string} idMeal - The parameter `idMeal` is a string that represents the ID of a meal. It is
 * used to fetch the planning slot meal recipes associated with that meal ID.
 * @returns the data fetched from the SLOT_MEAL_RECIPE_URL endpoint.
 */
export const getPlanningSlotMealRecipes = async (idMeal: string) => {
  const SLOT_MEAL_RECIPE_URL = `${API_URL}/planning-slot-meal-recipes/${idMeal}/`
  const response = await fetch(SLOT_MEAL_RECIPE_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
}


/**
 * The function updates a custom shopping list item's checked value in a public planning.
 * whether an item in the shopping list should be checked or unchecked. If `checkedValue` is `true`, it
 * means the item should be checked. If `checkedValue` is `false`, it means the item should be
 * unchecked.
 * want to update.
 * public planning. It is used to construct the URL for updating the custom shopping list. If provided,
 * it will be included in the URL as part of the path.
 * @returns the response from the API call in JSON format.
 * @param planningId
 */
export async function updateShoppingListAll(planningId?: string) {
  try {
    const UPDATE_SHOPPING_LIST_URL = `${API_URL}/public-plannings/${planningId}/update_custom_shopping_list_all/`
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(UPDATE_SHOPPING_LIST_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: headers,
    })
    if (!response.ok) {
      throw new Error('Erreur lors de la mise à jour')
    }

    return response.json()
  } catch (error) {
    console.error('Erreur lors de la mise à jour', error)
    throw error
  }
}


/**
 * The function `updateMealRecipe` updates a meal recipe by sending a PUT request to the API with the
 * new recipe data.
 * update. It is a string value.
 * @param recipeSlotId
 * @param {string} idNewMeal - The `idNewMeal` parameter is the ID of the new meal recipe that you want
 * to update.
 * @returns the data received from the API response if the response is successful. If the response is
 * not successful, it throws an error.
 */
export const updateMealRecipe = async (recipeSlotId: string, idNewMeal: string) => {
  const UPDATE_PLANNING_SLOT_MEAL_RECIPE_URL = `${API_URL}/planning-slot-meal-recipes/${recipeSlotId}/`
  const NEW_MEAL_DATA_URL = `${API_URL}/public-recipes/${idNewMeal}/`

  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(UPDATE_PLANNING_SLOT_MEAL_RECIPE_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({recipe: NEW_MEAL_DATA_URL, modified_by_users: true}),
    })
    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      throw new Error(`Failed to update meal recipe: ${response.statusText}`)
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * The function `updateRefusedIngredients` is an asynchronous function that sends a PUT request to
 * update the list of refused ingredients for a customer.
 * @param {string} customerId - The `customerId` parameter is a string that represents the unique
 * identifier of the customer for whom the refused ingredient is being updated. This identifier is used
 * to specify the customer whose refused ingredients are being modified.
 * @param {string} idIngredient - The `idIngredient` parameter is the ID of the ingredient that you
 * want to update as refused for a specific customer.
 * @returns the data received from the API response if the response is successful. If the response is
 * not successful, it throws an error.
 */
export const updateRefusedIngredients = async (customerId: string, idIngredient: string) => {
  const REFUSED_ING_URL = `${API_URL}/customers/${customerId}/add_or_remove_refused_ingredient/`

  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(REFUSED_ING_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        customer_id: customerId,
        adding: true,
        ingredient_id: idIngredient,
        removing: false,
      }),
    })

    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      throw new Error(`Failed to update refused ingredients: ${response.statusText}`)
    }
  } catch (error) {
    console.error('Error updating refused ingredients:', error)
    throw error
  }
}
