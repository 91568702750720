/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {AllPlanningWidget} from './components/AllPlanningWidget'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {Planning} from './components/Planning'
import {CourseList} from '../course-list/CourseList'
import TitleManager from '../../../_metronic/layout/components/title-manager/TitleManager'
import {brevoTrackEvent} from "../../modules/brevo/_requests";
import {getCustomers} from "../../modules/profile/core/_requests";

const PlanningPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <TitleManager title='Mes plannings - Sleem ' />
      <AllPlanningWidget className='card-xl-stretch mb-xl-8'></AllPlanningWidget>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const AllPlanningWrapper: FC = () => {

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')
        if (customerId) {
          const customerDetail = await getCustomers(customerId)
          localStorage.setItem('planning_credit_available', customerDetail.planning_credit_available)
        }
      } catch (error) {
        console.error('Error fetching customer data:', error)
      }
    }

    try {
      fetchCustomerData()

      // BREVO TRACKING
      const eventName = "has_viewed_all_plannings_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<PlanningPage />} />
        <Route path=':id' element={<Planning />} />
        <Route path='shopping-list/:id' element={<CourseList />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Routes>
      <Outlet />
    </>
  )
}

export {AllPlanningWrapper}
