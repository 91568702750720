
const ToolbarReloadAssistant = () => {
    function reloadPage () {
        window.location.reload()
    }

    function setButtonPwa () {
        console.log(window.innerWidth)
        if (window.innerWidth >= 1024) {
            return('btn btn-danger d-none')
        } else {
            return('btn btn-danger')
        }
    }

    return (
        <button id="reload-assistant" className={setButtonPwa()} onClick={reloadPage}>
            Recharger la page
        </button>
    )
}

export {ToolbarReloadAssistant}
