/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCustomerInfo} from "../../../account/_requests";
import {Link} from "react-router-dom";
import {getAllPlanningsByCustomer} from "../../core/_requests";


const ToolbarAllPlanning = () => {
    const [customer, setCustomer] = useState<any>({})
    const [goodText, setGoodText] = useState(<span className='text-muted'>Chargement...</span>)
    const [goodButton, setGoodButton] = useState(<span></span>)
    const navigate = useNavigate()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')!
        const customer = await getCustomerInfo(customerId)
        setCustomer(customer)
          const data = await getAllPlanningsByCustomer(customerId)
          const plannings_doing = data.filter((planning) => (planning.status === 1 || planning.status === 2))


        if (customer.planning_credit_available === 0) {
          setGoodText(<span className='text-muted'>Vous n'avez <Link className="text-decoration-underline" to={'/renewal'}>plus de planning</Link> disponible</span>)
          setGoodButton(
              <Link id='check-q2' className='btn btn-danger' to={'/renewal'}>
                Je prolonge
              </Link>
          )

        } else if (customer.planning_credit_available === 1) {
            setGoodText(<span className='text-muted'>
                Vous avez <span>
                <Link className="text-decoration-underline" to={"/my-account"}>
                     1 nouveau planning
                </Link>
                 </span> disponible</span>)
            if (plannings_doing.length === 0) {
                setGoodButton(
                    <a id='check-q2' className='btn btn-danger' rel={"noreferrer"} target={'_blank'} href={`https://form.typeform.com/to/DMJe4gsP#email=${customer.email}`}>
                        Nouveau planning
                    </a>
                )
            }

        } else {
            setGoodText(<span className='text-muted'>
                Vous avez <Link className="text-decoration-underline" to={"/my-account"}>
                {customer.planning_credit_available} nouveaux plannings
            </Link> disponibles</span>)
            if (plannings_doing.length === 0) {
                setGoodButton(
                    <a id='check-q2' className='btn btn-danger' rel={"noreferrer"} target={'_blank'} href={`https://form.typeform.com/to/DMJe4gsP#email=${customer.email}`}>
                        Nouveau planning
                    </a>
                )
            }
        }

      } catch (error) {
        console.error('Error fetching customer', error)
        navigate('/error/404')
      }
    }
    fetchData()
  }, [navigate])

  return (
    <div className='toolbar-all-planning container-fluid d-flex flex-stack !px-0'>
      {/* begin::Page title */}
      <div className='page-title d-flex flex-column justify-content-center flex-shrink me-3 mt-20 mt-lg-5 mb-5'>
        {/* begin::Title */}
        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
          Mes plannings
        </h1>
        {/*end::Title*/}

        {/*begin::Breadcrumb*/}
        <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
          {/*begin::Item*/}
          <li className='text-muted'>
            {goodText}
          </li>
          {/*end::Item*/}
        </ul>
        {/*end::Breadcrumb*/}
      </div>
      {/*end::Page title*/}

      <div className='btn-new-planning d-flex flex-grow flex-end mt-20 mt-lg-5 mb-5'>
        {goodButton}
      </div>
    </div>
  )
}

export {ToolbarAllPlanning}
