/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../_metronic/helpers'
import {useState, useEffect, useCallback} from 'react'
import {getModifsCounter, getPdfFile} from '../../core/_requests'
import {useParams} from 'react-router-dom'
import {useModificationsCounter} from '../../context/ModificationsCounterContext'

const ToolbarPlanning = () => {
  const modificationsCounterContext = useModificationsCounter()
  const modificationsCounter = modificationsCounterContext.modificationsCounter
  const setModificationsCounter = modificationsCounterContext.setModificationsCounter
  const [isNotifVisible, setNotifVisible] = useState(false)
  const [isDownloadNotifVisible, setDownloadNotifVisible] = useState(false)

  const {id} = useParams()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is used to fetch the modifications counter from the server and update the state
  variable `modificationsDone` with the fetched value. */
  const loadModificationsCounter = useCallback(async () => {
    if (!id) {
      console.error('ID is undefined')
      return
    }
    try {
      const data = await getModifsCounter(id)
      const remainingModifications = 3 - data.modifications_counter
      setModificationsCounter(remainingModifications)
    } catch (error) {
      console.error('Erreur lors de la récupération du compteur de modifications:', error)
    }
  }, [id])

  useEffect(() => {
    loadModificationsCounter()
  }, [id])

  useEffect(() => {
    window.loadModificationsCounter = loadModificationsCounter
    return () => {
      delete window.loadModificationsCounter
    }
  }, [loadModificationsCounter])

  /**
   * The function `handleNotifClick` sets the visibility of a notification to true, and then after 5
   * seconds, sets the visibility to false.
   * @returns The function returned is a cleanup function that clears the timeout using clearTimeout.
   */
  const handleNotifClick = () => {
    setNotifVisible(true)
    const timerId = setTimeout(() => {
      setNotifVisible(false)
    }, 5000)

    return () => {
      clearTimeout(timerId)
    }
  }

  /**
   * The function handleCloseNotif is used to close a notification by setting its visibility to false.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data related to the event. In this case, the event parameter is used to stop
   * the event from propagating further up
   */
  const handleCloseNotif = (event) => {
    event.stopPropagation()
    setNotifVisible(false)
  }

  /**
   * The function handles the click event of a toggle button and toggles the visibility of daily and
   * weekly views based on the clicked button.
   * @param event - The event parameter is of type React.MouseEvent, which represents a mouse event in
   * React. It contains information about the event, such as the target element and the type of event
   * (e.g., click, mouseover, etc.).
   */
  const handleToggleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    const dailyView = document.getElementById('planning-daily-view')
    const weeklyView = document.getElementById('planning-weekly-view')
    const dailyToggle = document.getElementById('viewDailyStylePlanningToggle')
    const weeklyToggle = document.getElementById('viewWeeklyStylePlanningToggle')

    if (event.currentTarget.id === 'viewWeeklyStylePlanningToggle') {
      if (dailyView) dailyView.classList.add('d-none')
      if (weeklyView) weeklyView.classList.remove('d-none')
      if (dailyToggle) dailyToggle.classList.remove('d-none')
      if (weeklyToggle) weeklyToggle.classList.add('d-none')
    } else {
      if (dailyView) dailyView.classList.remove('d-none')
      if (weeklyView) weeklyView.classList.add('d-none')
      if (dailyToggle) dailyToggle.classList.add('d-none')
      if (weeklyToggle) weeklyToggle.classList.remove('d-none')
    }
  }

  /**
   * The function `handleDownloadClick` is an asynchronous function that retrieves a planning file for
   * a customer and displays a download notification.
   * @returns The function `handleDownloadClick` does not have a return statement.
   */
  const handleDownloadClick = async () => {
    try {
      const customerId = localStorage.getItem('customer_id')

      if (!customerId || !id) {
        console.error('Informations nécessaires pour le téléchargement manquantes.')
        return
      }

      await getPdfFile(customerId, id, '', 'planning')
      showDownloadNotification()
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier de planning:', error)
    }
  }

  /**
   * The function `showDownloadNotification` sets a download notification to be visible for 5 seconds
   * and then hides it.
   */
  const showDownloadNotification = () => {
    setDownloadNotifVisible(true)
    setTimeout(() => {
      setDownloadNotifVisible(false)
    }, 5000)
  }

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div className='m-0 btn-planning'>

        <a
          id='viewWeeklyStylePlanningToggle'
          className='btn btn-sm btn-icon h-25px'
          onClick={handleToggleClick}
        >
          <span className='svg-icon svg-icon-primary svg-icon-2x'>
            <KTSVG
              path='/media/svg/icons-sleem/layout-top-panel.svg'
              className='svg-icon-2x svg-icon-primary'
            />
          </span>
        </a>
        <a
          id='viewDailyStylePlanningToggle'
          className='btn btn-sm btn-icon h-25px d-none'
          onClick={handleToggleClick}
        >
          <span className='svg-icon svg-icon-primary svg-icon-2x'>
            <KTSVG
              path='/media/svg/icons-sleem/layout-left-panel.svg'
              className='svg-icon-2x svg-icon-primary'
            />
          </span>
        </a>
        <a
          id='changeRecipeCounterDiv'
          className='btn btn-sm btn-icon btn-bg-danger btn-active-color-primary h-25px'
          onClick={handleNotifClick}
        >
          <KTSVG path='/media/svg/icons-sleem/edit-counter.svg' className='svg-icon svg-icon-xl' />

          <span className='fs-8 text-white p-1' id='changeRecipeCounter'>
            {modificationsCounter}
          </span>
          <div
            id='notif'
            className={`position-fixed flex-column box w-50 w-lg-325px shadow-lg bg-success ${
              isNotifVisible ? '' : 'd-none'
            }`}
          >
            <div
              id='header'
              className='d-flex py-1 flex-row justify-content-between align-items-center'
            >
              <div className='d-flex flex-row align-items-center gap-2 px-4 py-2 text-white w-100'>
                <i id='icone' className='far fa-check-circle text-white fs-4'></i>
                <span className='fs-5' id='title'>
                  Information
                </span>
              </div>

              <button
                id='close_notification'
                type='button'
                className='btn btn-icon-success'
                onClick={handleCloseNotif}
              >
                <span className='svg-icon svg-icon-1 close-notif'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='#ffff'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='#ffff'
                    ></rect>
                  </svg>
                </span>
              </button>
            </div>
            <div
              id='description'
              className='d-flex align-items-center text-dark bg-white px-4 py-4'
            >
              Vous pouvez modifier jusqu'à 3 recettes de votre planning en cliquant sur le crayon !
            </div>
          </div>
        </a>
        <a id='download-file' className='btn btn-icon' onClick={handleDownloadClick}>
          <span className='svg-icon svg-icon-primary svg-icon-2x'>
            <KTSVG
              path='/media/svg/icons-sleem/mail-attachment.svg'
              className='svg-icon-2x svg-icon'
            />
          </span>
          <div
            id='notif'
            className={`position-fixed flex-column box w-50 w-lg-325px shadow-lg bg-success ${
              isDownloadNotifVisible ? '' : 'd-none'
            }`}
          >
            <div
              id='header'
              className='d-flex py-1 flex-row justify-content-between align-items-center'
            >
              <div className='d-flex flex-row align-items-center gap-2 px-4 py-2 text-white w-100'>
                <i id='icone' className='far fa-check-circle text-white fs-4'></i>
                <span className='fs-5' id='title'>
                  Demande envoyée
                </span>
              </div>

              <button
                id='close_notification'
                type='button'
                className='btn btn-icon-success'
                onClick={handleCloseNotif}
              >
                <span className='svg-icon svg-icon-1 close-notif'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='#ffff'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='#ffff'
                    ></rect>
                  </svg>
                </span>
              </button>
            </div>
            <div
              id='description'
              className='d-flex align-items-center text-dark bg-white px-4 py-4'
            >
              Vous allez recevoir votre document par email d'ici quelques minutes !
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export {ToolbarPlanning}
