import React, {createContext, useState, useContext, ReactNode} from 'react'

interface ModificationsCounterContextType {
  modificationsCounter: number | null
  setModificationsCounter: React.Dispatch<React.SetStateAction<number | null>>
}

const ModificationsCounterContext = createContext<ModificationsCounterContextType | null>(null)

interface ModificationsCounterProviderProps {
  children: ReactNode
}

export const ModificationsCounterProvider: React.FC<ModificationsCounterProviderProps> = ({
  children,
}) => {
  const [modificationsCounter, setModificationsCounter] = useState<number | null>(null)

  return (
    <ModificationsCounterContext.Provider value={{modificationsCounter, setModificationsCounter}}>
      {children}
    </ModificationsCounterContext.Provider>
  )
}

// Hook personnalisé pour utiliser le contexte
export const useModificationsCounter = () => {
  const context = useContext(ModificationsCounterContext)
  if (!context) {
    throw new Error(
      'useModificationsCounter doit être utilisé à l’intérieur d’un ModificationsCounterProvider'
    )
  }
  return context
}
