/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useParams, useNavigate, Link} from 'react-router-dom'
import {getWeeklyPlanning, getPlanningByDate} from '../../core/_requests'
import {ChangeRecipeModal} from '../../../../../_metronic/partials/modals/change-recipe/ChangeRecipeModal'
import {getMealRecipe} from '../../../../../_metronic/partials/modals/change-recipe/core/_requests'
import {useModificationsCounter} from '../../context/ModificationsCounterContext'

const URL_IMG_RECIPES = process.env.REACT_APP_URL_IMG_RECIPES
const DEFAULT_IMAGE = './public/media/images/plat-default.jpeg'

type Props = {
  className: string
}

const TAG_COLORS = {
  'Protéines Végétales': 'success',
  'Viande blanche': 'info',
  'Viande rouge': 'danger',
  Poisson: 'blue',
  Oeuf: 'warning',
}

const PlanningDay: React.FC<Props> = ({className}) => {
  const [dates, setDates] = useState<string[]>([])
  const [dailyPlannings, setDailyPlannings] = useState<{[date: string]: any[]}>({})
  const {id} = useParams<{id: string}>()
  const [activeTab, setActiveTab] = useState(0)
  const [showChangeRecipeModal, setChangeRecipeModal] = useState<boolean>(false)
  const [activeMealId, setActiveMealId] = useState<string | null>(null)
  const [activeMealData, setActiveMealData] = useState<any | null>(null)
  const [activeRecipeSlotId, setActiveRecipeSlotId] = useState<string | null>(null)
  const [currentDate, setCurrentDate] = useState<string | null>(null)
  const [reloadTrigger, setReloadTrigger] = useState<number>(0)
  const {modificationsCounter} = useModificationsCounter()
  const navigate = useNavigate()

  // FIXME === 0
  const isEditDisabled = modificationsCounter === 0

  /* The `useEffect` hook is used to retrieve data from the daily schedule and update the status of the component. */
  useEffect(() => {
    const loadWeeklyPlanning = async () => {
      try {
        if (typeof id === 'undefined') {
          console.error('id is undefined')
          return
        }

        const response = await getWeeklyPlanning(id)
        if (!Array.isArray(response.planning_slot_meals)) {
          throw new Error('planning_slot_meals is not an array')
        }

        const uniqueDates = Array.from(
          new Set(response.planning_slot_meals.map((slot) => slot.date as string))
        ) as string[]
        setDates(uniqueDates)

        const defaultDate = currentDate || (uniqueDates.length > 0 ? uniqueDates[0] : null)
        if (defaultDate) {
          const planningData = await getPlanningByDate(id, defaultDate)
          setDailyPlannings({[defaultDate]: planningData})
          setCurrentDate(defaultDate)
        }
      } catch (error) {
        console.error('Error fetching weekly planning', error)
        navigate('/error/404')
      }
    }

    loadWeeklyPlanning()
  }, [id, navigate])

  /**
   * The above code is a React useEffect hook that loads planning data for a specific date and updates
   * the state with the fetched data.
   */
  useEffect(() => {
    const loadDateData = async () => {
      if (!id || !currentDate || !dates.includes(currentDate)) {
        return
      }

      try {
        const planningData = await getPlanningByDate(id, currentDate)
        setDailyPlannings((prev) => ({...prev, [currentDate]: planningData}))
      } catch (error) {
        console.error(`Error fetching planning for date ${currentDate}`, error)
      }
    }

    loadDateData()
  }, [id, currentDate, reloadTrigger, dates])

  /**
   * The function `reloadPlanningData` increments the `reloadTrigger` state variable by 1.
   */
  const reloadPlanningData = () => {
    setReloadTrigger((prev) => prev + 1)
  }

  /**
   * The function `handleDateClick` sets the active tab and current date based on the date and index
   * passed as arguments.
   * @param date - The `date` parameter represents the selected date. It could be a specific date
   * object or a string representing a date.
   * @param index - The index parameter represents the index of the clicked date. It is used to
   * identify which date was clicked among a list of dates.
   * @param event - The `event` parameter is the event object that is triggered when the date is
   * clicked. It contains information about the event, such as the target element, the type of event,
   * and any additional data associated with the event. In this case, the `event.preventDefault()`
   * method is called to prevent the
   */
  const handleDateClick = (date, index, event) => {
    event.preventDefault()
    setActiveTab(index)
    setCurrentDate(date)
  }

  /**
   * The function `handleEditClick` is an asynchronous function that sets the active meal ID and recipe
   * slot ID, fetches meal recipe data using the `getMealRecipe` function, and sets the active meal
   * data and change recipe modal state.
   * @param {string} idRecipe - The `idRecipe` parameter is a string that represents the ID of a
   * recipe. It is used to identify a specific recipe in the application.
   * @param {string} recipeSlotId - The `recipeSlotId` parameter is the ID of the recipe slot that is
   * being edited. It is used to identify the specific recipe slot that the user wants to edit.
   */
  const handleEditClick = async (idRecipe: string, recipeSlotId: string) => {
    try {
      setActiveMealId(idRecipe)
      setActiveRecipeSlotId(recipeSlotId)
      const mealData = await getMealRecipe(idRecipe)
      setActiveMealData(mealData)
      setChangeRecipeModal(true)
    } catch (error) {
      console.error('Error fetching meal recipe', error)
    }
  }

  return (
    <div className={`card ${className}`} id='planning-daily-view'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar card-day w-full'>
          <ul className='nav nav-day w-full justify-center'>
            {dates.map((date, index) => (
              <li className='nav-item' key={index}>
                <a
                  id={date}
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-danger rounded-pill py-4 px-2 ${
                    index === activeTab ? 'active' : ''
                  } fw-bold px-4 me-1`}
                  data-bs-toggle='tab'
                  onClick={(e) => handleDateClick(date, index, e)}
                >
                  <span className='day-name opacity-50 fs-7 fw-semibold'>
                    {new Date(date)
                      .toLocaleDateString('fr-FR', {
                        weekday: 'short',
                      })
                      .replace('.', '')}
                  </span>{' '}
                  <span className='day-number fs-6 fw-bold'>
                    <strong>
                      {new Date(date).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                      })}
                    </strong>
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 px-md-8 px-3'>
        <div className='tab-content'>
          {dates.map((date, index) => (
            <div
              className={`tab-pane fade ${index === activeTab ? 'show active' : ''}`}
              id={`kt_table_widget_5_tab_${index + 1}`}
              key={index}
            >
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='border-0'>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-100px'></th>
                      <th className='p-0 min-w-80px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailyPlannings[date]?.map((meal, mealIndex) => {
                      if (!meal || !meal.planning_slot_meal_recipes) {
                        console.error('Meal data is invalid')
                        return null
                      }

                      return (
                        <React.Fragment key={mealIndex}>
                          {mealIndex === 0 && (
                            <tr className='border-bottom-0'>
                              <td className='fs-6 fw-bold text-gray-400' colSpan={4}>
                                <strong className='min-w-100px pb-0 pt-8'>Midi</strong>
                              </td>
                            </tr>
                          )}
                          {mealIndex === Math.floor(dailyPlannings[date].length / 2) && (
                            <tr className='border-bottom-0'>
                              <td className='fs-6 fw-bold text-gray-400' colSpan={4}>
                                <strong className='min-w-100px pb-0 pt-8'>Soir</strong>
                              </td>
                            </tr>
                          )}
                          {meal.planning_slot_meal_recipes.length > 0 ? (
                            meal.planning_slot_meal_recipes.map((recipeSlot, recipeSlotIndex) => {
                              if (!recipeSlot || !recipeSlot.recipe) {
                                return null
                              }

                              return (
                                <tr key={`${mealIndex}-${recipeSlotIndex}`} id={recipeSlot.id}>
                                  <td>
                                    <div
                                      className='symbol w-12 h-12 me-3'
                                      style={{
                                        backgroundImage: `url(${
                                          recipeSlot.recipe?.main_picture
                                            ? URL_IMG_RECIPES + recipeSlot.recipe.main_picture
                                            : DEFAULT_IMAGE
                                        })`,
                                        backgroundSize: 'cover',
                                        width: '50px',
                                        height: '50px',
                                      }}
                                    ></div>
                                  </td>
                                  <td className='recipe-name-tag d-flex justify-content-start flex-column px-0'>
                                    <Link
                                      to={`/recipe/${recipeSlot.recipe.id}`}
                                      state={{from: 'my-planning', planningId: id}}
                                      className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                    >
                                      {recipeSlot.recipe.name}
                                    </Link>

                                    <div className={'flex'}>
                                      <span
                                        style={{marginRight: '5px'}}
                                        className={`badge py-3 px-4 fs-9 
                                            badge-light-dark flex-wrap`}
                                      >
                                        {recipeSlot.course.name}
                                      </span>
                                      {recipeSlot.recipe.tags
                                        .filter(
                                          (tag) => tag.name !== 'No tag' && TAG_COLORS[tag.name]
                                        )
                                        .map((tag) => (
                                          <span
                                            style={{marginRight: '5px'}}
                                            className={`badge py-3 px-4 fs-9 
                                              badge-light-${TAG_COLORS[tag.name]} flex-wrap`}
                                          >
                                            {tag.name}
                                          </span>
                                        ))}
                                    </div>
                                  </td>
                                  <td
                                    className={`btn-edit-access ${
                                      isEditDisabled ? 'disabled' : ''
                                    }`}
                                  >
                                    <a
                                      href='#'
                                      className={`btn btn-sm btn-icon btn-bg-light btn-edit-recipe ${
                                        isEditDisabled ? 'disabled-link' : ''
                                      }`}
                                      onClick={(e) => {
                                        if (isEditDisabled) {
                                          e.preventDefault()
                                          return
                                        }
                                        handleEditClick(recipeSlot.recipe.id, recipeSlot.id)
                                      }}
                                      id={`change_recipe_${recipeSlot.recipe.id}`}
                                      data-id={recipeSlot.id}
                                    >
                                      <KTSVG
                                        path='/media/svg/icons-sleem/edit.svg'
                                        className={`svg-icon-2 fs-2 btn btn-sm btn-icon ${
                                          recipeSlot.modified_by_users
                                            ? 'btn-bg-success'
                                            : 'btn-bg-danger'
                                        } btn-active-color-primary w-30px h-30px`}
                                      />
                                    </a>
                                    <Link
                                      to={`/recipe/${recipeSlot.recipe.id}`}
                                      state={{from: 'my-planning', planningId: id}}
                                      className='btn btn-sm btn-icon btn-bg-danger w-30px h-30px'
                                    >
                                      <i className='fas fa-arrow-right text-white'></i>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={3}>Aucune recette pour ce repas.</td>
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ChangeRecipeModal
        show={showChangeRecipeModal}
        handleClose={() => setChangeRecipeModal(false)}
        mealData={activeMealData}
        recipeSlotId={activeRecipeSlotId}
        onRecipeChanged={reloadPlanningData}
      />
    </div>
  )
}

export {PlanningDay}
