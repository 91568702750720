const API_URL = process.env.REACT_APP_API_URL
const customerId = localStorage.getItem('customer_id')
export const BREVO_UPDATE_CUSTOMER_URL = `${API_URL}/customers/${customerId}/brevo_update_customer/`
export const BREVO_CREATE_CUSTOMER_URL = `${API_URL}/customers/${customerId}/brevo_create_customer/`
export const BREVO_TRACK_EVENT_URL = `${API_URL}/customers/${customerId}/brevo_track_event/`


export async function brevoUpdateCustomer() {
  if (customerId) {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(BREVO_UPDATE_CUSTOMER_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({}),
    })
    if (!response.ok) {
      console.error('Erreur - brevoUpdateCustomer', response)
      localStorage.clear()
      document.location.reload()
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erreur - brevoUpdateCustomer', error)
    localStorage.clear()
    document.location.reload()
  }
}}

export async function brevoCreateCustomer(email: string, attributes) {
  if (customerId) {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(BREVO_CREATE_CUSTOMER_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        email: email,
        attributes: attributes,
      }),
    })
    if (!response.ok) {
      console.error('Erreur - brevoCreateCustomer', response)
      localStorage.clear()
      document.location.reload()
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erreur - brevoCreateCustomer', error)
    localStorage.clear()
    document.location.reload()
  }
}}

export async function brevoTrackEvent(eventName: string, eventData) {
  if (customerId) {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(BREVO_TRACK_EVENT_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        event_name: eventName,
        event_data: eventData
      }),
    })
    if (!response.ok) {
      console.error('Erreur - brevoTrackEvent', response)
      localStorage.clear()
      document.location.reload()
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erreur - brevoTrackEvent', error)
    localStorage.clear()
    document.location.reload()
  }
}}
