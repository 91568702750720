import {FC, useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {usePlanningDates} from '../../../_metronic/helpers'
import {getCourseList} from './core/_requests'
import {updateShoppingList} from './core/_requests'
import {useReplaceUnitName} from '../../../_metronic/helpers'
import TitleManager from '../../../_metronic/layout/components/title-manager/TitleManager'
import {brevoTrackEvent} from "../../modules/brevo/_requests";

const listCourseBreadCrumbs: Array<PageLink> = [
  {
    title: 'Retour vers mes plannings',
    path: '/my-planning',
    isSeparator: false,
    isActive: false,
  },
]

const CourseList: FC = () => {
  const {id} = useParams()
  const {startDate, endDate, formattedDate} = usePlanningDates(id)
  const [groupedItems, setGroupedItems] = useState({})
  const navigate = useNavigate()
  const replaceUnitName = useReplaceUnitName()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) {
          throw new Error('ID is undefined')
        }

        const items = await getCourseList(id)

        const grouped = items.custom_shopping_list_items.reduce((acc, item) => {
          const catName = item.ingredient.category.name
          const catEmojiUrl = item.ingredient.category.emoji_picture

          acc[catName] = acc[catName] || {items: [], emojiUrl: catEmojiUrl}
          acc[catName].items.push(item)
          return acc
        }, {})

        for (let category in grouped) {
          grouped[category].items.sort((a, b) =>
            a.is_checked === b.is_checked ? 0 : a.is_checked ? 1 : -1
          )
        }

        setGroupedItems(grouped)
      } catch (error) {
        console.error('Error fetching course list', error)
        navigate('/error/404')
      }
    }

    fetchData()
  }, [id, startDate, endDate, navigate])

  /**
   * The function handles the change of a checkbox for an item in a shopping list and updates the state
   * accordingly.
   * @param {string} itemId - The `itemId` parameter is a string that represents the unique identifier
   * of an item in the shopping list.
   * @param {boolean} isChecked - The `isChecked` parameter is a boolean value that indicates whether
   * the checkbox for an item is checked or not.
   */
  const handleCheckboxChange = async (itemId: string, isChecked: boolean) => {
    await updateShoppingList(isChecked, itemId, id)

    setGroupedItems((prev) => {
      const newGroupedItems = {...prev}
      for (let category in newGroupedItems) {
        newGroupedItems[category].items = newGroupedItems[category].items
          .map((item) => (item.id === itemId ? {...item, is_checked: isChecked} : item))
          .sort((a, b) => (a.is_checked === b.is_checked ? 0 : a.is_checked ? 1 : -1))
      }
      return newGroupedItems
    })
  }

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_course_list_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  return (
    <>
      <TitleManager title='Liste de courses - Sleem ' />
      <PageTitle breadcrumbs={listCourseBreadCrumbs}>
        {formattedDate ? `Ma liste de courses ${formattedDate}` : 'Ma liste de courses du'}
      </PageTitle>
      <div id="courseListInstructions" className='d-flex flex-column mb-3'>
        <span className='fst-italic text-dark'>- Cliquez sur un aliment pour le rayer</span>
        <span className='fst-italic text-dark'>
          - Les petits déjeuners, desserts et bonus ne sont pas pris en compte
        </span>
        <span className='fst-italic text-item-optional'>
          - Les ingrédients optionnels sont en vert et en italique
        </span>
      </div>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {Object.keys(groupedItems).map((category) => (
          <div className='col-lg-4 col-xl-4 col-md-6 col-sm-12' key={category}>
            <div className='card card-custom gutter-b shadow-sm'>
              <div className='card-header header-course-list justify-content-center align-items-center mb-25'>
                <div className='card-title'>
                  <h3 className='card-label flex flex-row '>
                    <img
                      src={`https://res.cloudinary.com/hv0mwtwcz/${groupedItems[category].emojiUrl}`}
                      alt={category}
                      style={{width: '24px', height: '24px', marginRight: '8px'}}
                    />
                    {category}
                  </h3>
                </div>
              </div>
              <div className='card-body d-flex flex-column w-100'>
                <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
                  {groupedItems[category].items.map((item) => (
                    <div
                      className={`col-lg-12 col-xl-12 col-md-12 col-sm-12 mt-2 ${
                        item.is_optional ? 'text-item-optional fst-italic' : ''
                      } ${item.is_checked ? 'line-through text-gray-500' : ''}`}
                      key={item.id}
                    >
                      <span className='float-start'>
                        <input
                          id={`check_box_${item.ingredient.id}`}
                          className='checker checkbox checkbox-success cursor-pointer'
                          type='checkbox'
                          checked={item.is_checked}
                          onChange={() => handleCheckboxChange(item.id, !item.is_checked)}
                        ></input>
                        <span id={item.ingredient.id} className='mx-2 fs-3'>
                          {item.ingredient.name}
                        </span>
                      </span>
                      <span className='float-end fs-3'>
                        {item.quantity} {replaceUnitName(item.unit.name)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export {CourseList}
