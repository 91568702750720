import {Link} from "react-router-dom";
import React from "react";

const PlanningCredits = ({customer}) => {

    return (
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border" data-bs-toggle="collapse"
             data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Mes plannings</h3>
          </div>
        </div>
        <div id="kt_account_settings_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row d-flex justify-content-left align-items-center">

              <div className="col-sm-12 col-md-12 col-lg-3">
                <div className="text-center d-flex flex-column justify-content-center align-items-center rounded mb-4 bg-primary p-4 rounded-md border justify-content-center align-items-center border-primary  bg-opacity-10">
                  <span className="h3 text-primary">Total plannings</span>
                  <span className="display-6 text-primary fw-bold my-3">{customer.planning_credit_total}</span>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <div
                    className="text-center d-flex flex-column justify-content-center align-items-center rounded mb-4 bg-warning p-4 rounded-md border justify-content-center align-items-center border-warning bg-opacity-10 ">
                  <span className="h3 text-warning">Plannings utilisés</span>
                  <span className="display-6 text-warning fw-bold my-3">{customer.planning_credit_used}</span>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <div
                    className="text-center d-flex flex-column justify-content-center align-items-center rounded mb-4 bg-success rounded-md border border-success p-4 bg-opacity-10 flex justify-center align-center  bg-opacity-10">
                  <span className="h3 text-success">Plannings restants</span>
                  <span className="display-6 text-success fw-bold my-3">{customer.planning_credit_available}</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-5">
              <div>
                <Link to={'/renewal'} className="btn btn-danger btn-active-light-danger">
                  Je prolonge
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default PlanningCredits
