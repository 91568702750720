import React from 'react'

interface PaginationProps {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  paginate: (pageNumber: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  paginate,
}) => {
  const pageNumbers: number[] = []

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  return (
    <nav className='...'>
      <ul className='pagination'>
        {pageNumbers.map((number) => (
          <li key={number} className='page-item cursor-pointer'>
            <a
              onClick={() => paginate(number)}
              className={`page-link ${currentPage === number ? 'bg-danger text-white' : ''}`}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Pagination
