const API_URL = process.env.REACT_APP_API_URL

export const getCustomerInfo = async (customerId: string) => {
  const CUSTOMER_INFO_URL = `${API_URL}/customers/${customerId}/`
  const response = await fetch(CUSTOMER_INFO_URL, {
    credentials: 'include',
  })
  return await response.json()
}

