import React, {FC, useEffect, useState} from "react";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";
import {brevoTrackEvent} from "../../modules/brevo/_requests";
import {PageTitle} from "../../../_metronic/layout/core";


const AiAssistantPage: FC = () => {
    const [isLoading, setIsLoading] = useState(true)

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_ai_assistant_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  // TYPEBOT HTML / CSS / JS
  useEffect(() => {
    try {
        const typebotId = "votre-assistant-sleem"
        const customerEmail = localStorage.getItem("email")
        const customerFirstName = localStorage.getItem("first_name")
        const typebotInitScript = document.createElement("script");
        typebotInitScript.type = "module";
        typebotInitScript.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2/dist/web.js'
        Typebot.initStandard({
         "typebot": "${typebotId}",
         "prefilledVariables": {
            "Email": "${customerEmail}", 
            "Name": "${customerFirstName}"
          }
         })`

        document.body.append(typebotInitScript);

        console.log(typebotInitScript.innerHTML)

        setIsLoading(false)

    } catch (error) {
      console.error(error)
    }
  }, [])


    if (isLoading) return <div>Chargement...</div>

  return (
      <>
        <TitleManager title='Assistant - Sleem' />
        <PageTitle>Mon assistant Sleem</PageTitle>

          <div dangerouslySetInnerHTML={{ __html: `<typebot-standard style="width: 100%; height: 600px; "></typebot-standard>` }} />
      </>

  )
}

export {AiAssistantPage}
