import React from 'react'

const SearchBar = ({searchValue, setSearchValue}) => {
  return (
    <div className='d-flex justify-content-end mt-xl-1'>
      <input
        type='text'
        name={'recipe_search'}
        style={{
          borderRadius: '15px',
          outlineColor: '#F40F63',
          outlineOffset: '2px',
          marginBottom: '30px',
        }}
        className='border-0 px-4 py-2 w-100 w-md-25'
        placeholder='Rechercher une recette'
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  )
}

export default SearchBar
