import React, {useState} from 'react'
import {ShowMealRecipe} from '../components/ShowMealRecipe'

type Step4Props = {
  mealData: any
  onCancel: () => void
  allRecipesPresented?: boolean
}

const Step4: React.FC<Step4Props> = ({mealData, onCancel, allRecipesPresented}) => {
  const [showRecipe, setShowRecipe] = useState(false)

  return (
    <>
      {/*begin::Step 4 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        {allRecipesPresented ? (
          <div className='text-center p-5'>
            <div className='pt-3 d-flex flex-column'>
              <p className='text-center text-white fs-2 m-3 card-title-change-recipe-white'>
                Malheureusement, aucune recette n'a été trouvée par Sleemy !
              </p>
              <p className='text-center text-white fs-5 m-3 card-title-change-recipe-white'>
                Merci de bien vouloir nous contacter depuis l'Aide en bas à droite de votre écran ou
                nous envoyer un mail à{' '}
                <a style={{color: '#1e8be6'}} href='mailto:hello@sleem.io'>
                  hello@sleem.io
                </a>
              </p>
            </div>
          </div>
        ) : (
          <div className='w-100'>
            {/*begin::Form Group */}
            <div className='fv-row text-center'>
              <h3 className='text-center text-white mb-5'>{mealData.name}</h3>
              <div
                className='symbol w-12 h-12 me-3'
                style={{
                  backgroundImage: `url(https://res.cloudinary.com/hv0mwtwcz/${mealData.main_picture})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '150px',
                  height: '150px',
                }}
              ></div>
              <div>
                <span
                  onClick={() => setShowRecipe(!showRecipe)}
                  className='btn btn-link-white text-white'
                >
                  {showRecipe ? 'Retour' : 'En savoir plus'}
                </span>
                {showRecipe && (
                  <div id='meal_recipes' className='bg-white p-6 rounded'>
                    <ShowMealRecipe
                      className='card-xl-stretch mb-xl-8'
                      ingredients={mealData.recipe_ingredients}
                      informations={mealData}
                    />
                  </div>
                )}
              </div>
            </div>
            {/*end::Form Group */}

            <p className='text-white text-center pt-10 pb-0'>Cette recette vous convient-elle ?</p>
          </div>
        )}
      </div>
      {/*end::Step 4 */}
    </>
  )
}

export {Step4}
