/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useNavigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const RedirectHandler: FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const checkUserStatus = () => {
      const excludedPaths = ['/my-account', '/faq']
      if (!excludedPaths.includes(location.pathname)) {
        if (currentUser?.id) {
          try {
            if (currentUser.status !== 2) {
              navigate('/renewal')
            }
          } catch (error) {
            console.error('Failed to fetch user status:', error)
          }
        }
      }
    }

    checkUserStatus()
  }, [currentUser, navigate, location.pathname])

  return null
}

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {currentUser ? (
            <>
              <Route
                path='/*'
                element={
                  <>
                    <RedirectHandler />
                    <PrivateRoutes />
                  </>
                }
              />
              <Route path='logout' element={<Logout />} />
              <Route path='error/*' element={<ErrorsPage />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
