const API_URL = process.env.REACT_APP_API_URL

export const getCustomerInfo = async (customerId: string) => {
  const CUSTOMER_INFO_URL = `${API_URL}/customers/${customerId}/`
  const response = await fetch(CUSTOMER_INFO_URL, {
    credentials: 'include'
  })
  return await response.json()
}

export const updateSubStatus = async (customerId: string, subscribedSms: boolean, subscribedNewsletter: boolean, subscribed:boolean) => {
  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    console.log(customerId, subscribedSms, subscribedNewsletter, subscribed)
    const response = await fetch(`${API_URL}/customers/${customerId}/update_subscription_preferences/`, {
      method: 'PUT',
      redirect: 'follow',
      headers: headers,
      body: JSON.stringify({
        'subscribed_sms': subscribedSms,
        'subscribed_newsletter': subscribedNewsletter,
        'subscribed': subscribed
      }),
    })

    const data = await response.json()
    console.log(data)
    return data
  } catch (error) {
    console.error("Erreur lors de l'envoie de la demande", error)
    throw error
  }
}

