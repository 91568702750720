import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {Sidebar} from './components/sidebar'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {LikeRecipe} from '../partials/modals/like-recipe/LikeRecipe'
import {ModificationsCounterProvider} from '../../app/pages/planning/context/ModificationsCounterContext'
import '../sleem_custom.css'

const MasterLayout = () => {
  const location = useLocation()

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const [rating, setRating] = useState(0)

  const [modificationsCounter, setModificationsCounter] = useState(3)

  return (
    <PageDataProvider>
      <ModificationsCounterProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper special-app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Modals */}
        <LikeRecipe rating={rating} setRating={setRating} />
        {/* end:: Modals */}
        {/* <ScrollTop /> */}
      </ModificationsCounterProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
