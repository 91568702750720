import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";
import {PageTitle} from "../../../_metronic/layout/core";
import {getCustomerInfo} from "./_requests";
import {brevoTrackEvent} from "../../modules/brevo/_requests";
import PasswordResetFormInApp from "./ResetPasswordFromApp";
import NotificationToggles from "./SubUserPreferencesNotificationToggle";
import PlanningCredits from "./PlanningCredits";
import Rdv from "./Rdv";
import Advantages from "./Advantages";
import Community from "./Community";


const AccountWrapper: FC = () => {
    const [customer, setCustomer] = useState<any>({})
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')!
        const customer = await getCustomerInfo(customerId)
        setCustomer(customer)

          setIsLoading(false)

      } catch (error) {
        console.error('Error fetching customer', error)
        navigate('/error/404')
      }
    }
    fetchData()
  }, [navigate])

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_account_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);


  if (isLoading) return <div>Chargement...</div>


  return (
    <>
      <TitleManager title='Mon compte - Sleem' />

      <PageTitle>Mon compte</PageTitle>

      <PlanningCredits customer={customer}/>

      <Rdv customer={customer}/>

      <Advantages customer={customer}/>

      <Community customer={customer}/>

      <PasswordResetFormInApp username={customer.email} customerId={customer.id}/>

      <NotificationToggles customer={customer}/>

    </>
  )
}

export {AccountWrapper}
