import React, {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {Crisp} from 'crisp-sdk-web'
import TagManager from 'react-gtm-module'


const App = () => {
  useEffect(() => {
      // LOG THE PAGE HISTORY
      const currentPage = window.location.pathname
      localStorage.setItem("pageHistory", currentPage)

      // CRISP CONFIGURATION
      Crisp.configure('3d0ba310-13f0-48ef-a395-0401bd29e9ab')
      try {
        const customerEmail = localStorage.getItem('email')!
        Crisp.user.setEmail(customerEmail);
      } catch (e) {
        console.log(e)
      }

      // TAG MANAGER CONFIGURATION
      if (window.location.hostname.includes('staging') || window.location.hostname.includes('localhost')) {
          TagManager.initialize(
              {
                  gtmId: 'GTM-TV5LSXL',
                  auth: 'RbDX0F5My1JhkL2cbprnog',
                  preview: 'env-69'
              })
      } else {
          TagManager.initialize(
              {
                  gtmId: 'GTM-TV5LSXL',
              })
      }
      TagManager.dataLayer({
          dataLayer: {
              event: 'pageview',
              pagePath: window.location.pathname,
              pageTitle: window.location.pathname.replace("/", ""),
          },
      });
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
