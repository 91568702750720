/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'

type Props = {
  className: string
  ingredients: {ingredient: {id: string; name: string; main_picture: string}}[]
  onCheckboxChange: (ingredientId: string, isSelected: boolean) => void
}

const ShowRemoveMealRecipe: React.FC<Props> = ({className, ingredients, onCheckboxChange}) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])

  const handleCheckboxChange = (ingredientId: string) => {
    if (selectedCheckboxes.includes(ingredientId)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== ingredientId))
      onCheckboxChange(ingredientId, false)
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, ingredientId])
      onCheckboxChange(ingredientId, true)
    }
  }

  return (
    <div className='table-responsive'>
      <h3>Quels ingrédients n'aimez-vous pas ?</h3>
      <p>Vous ne retrouverez plus les ingrédients choisis dans vos prochaines recettes</p>
      {/* begin::Table */}
      <table className='table align-middle gs-0 gy-5'>
        {/* begin::Table head */}
        <thead>
          <tr>
            <th className='p-0 w-50px'></th>
            <th className='p-0 min-w-100px'></th>
            <th className='p-0 '></th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {ingredients.map((ing, index) => (
            <tr key={index}>
              <td className='p-0 pb-1'>
                <div className='symbol symbol-50px me-2'>
                  <span className='symbol-label'>
                    <img
                      src={`https://res.cloudinary.com/hv0mwtwcz/${ing.ingredient.main_picture}`}
                      className='h-50 align-self-center'
                      alt={ing.ingredient.name}
                    />
                  </span>
                </div>
              </td>
              <td className='p-0'>
                <p className='text-dark fw-bold text-hover-primary'>{ing.ingredient.name}</p>
              </td>
              <td className='flex justify-end'>
                <input
                  type='checkbox'
                  id={ing.ingredient.id}
                  className='input-remove-tag cursor-pointer'
                  name={ing.ingredient.name}
                  value={ing.ingredient.id}
                  checked={selectedCheckboxes.includes(ing.ingredient.id)}
                  onChange={() => handleCheckboxChange(ing.ingredient.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table container */}
    </div>
  )
}

export {ShowRemoveMealRecipe}
