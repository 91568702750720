import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {logout} from './_requests'
import {getCustomers} from '../../profile/core/_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const TOKEN_EXPIRATION_DURATION = 48 * 60 * 60 * 1000

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const logoutTimerRef = useRef<NodeJS.Timeout | null>(null)

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      resetLogoutTimer()
    } else {
      authHelper.removeAuth()
      clearLogoutTimer()
    }
  }

  const resetLogoutTimer = () => {
    clearLogoutTimer()
    logoutTimerRef.current = setTimeout(logoutUser, TOKEN_EXPIRATION_DURATION)
  }

  const clearLogoutTimer = () => {
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current)
      logoutTimerRef.current = null
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser')
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser))
    }
  }, [])

  const logoutUser = async () => {
    try {
      await logout()
      saveAuth(undefined)
      setCurrentUser(undefined)
      localStorage.clear()
      clearLogoutTimer()
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout: logoutUser}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const initializeUser = async () => {
      try {
        if (!didRequest.current) {
          const customerId = localStorage.getItem('customer_id')
          const csrfToken = localStorage.getItem('csrftoken')

          if (customerId && csrfToken) {
            const userData = await getCustomers(customerId)
            setCurrentUser({
              id: userData.id,
              status: userData.status,
              first_name: userData.first_name,
              last_name: userData.last_name,
              password: undefined,
              email: userData.email,
              auth: {
                customer_id: customerId,
                csrftoken: csrfToken,
              },
            })
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const customerId = localStorage.getItem('customer_id')
    if (customerId) {
      initializeUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
