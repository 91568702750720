import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";
import {PageTitle} from "../../../_metronic/layout/core";
import {getCustomerInfo} from "./_requests";
import {brevoTrackEvent} from "../../modules/brevo/_requests";
import {getCustomers} from "../../modules/profile/core/_requests";


function goodTextForRenewal(customer) {
  if (customer.status == 3) {
    return(
      <h1 className="fs-2hx fw-bold mb-5">
        Votre programme <span className="text-danger">Sleem a pris fin</span>.
        <p className="text-muted fs-4 pt-3">Pour accéder de nouveau à votre espace et continuer à
          recevoir des plannings, il vous suffit de choisir une de nos offres.</p>
      </h1>
    )
  } else {
    return (
        <h1 className="fs-2hx fw-bold mb-5">
          Choisissez l'offre <span className="text-danger"> la plus adaptée</span> à vos besoins
        </h1>
    )
  }
}

const RenewalWrapper: FC = () => {
  const [customer, setCustomer] = useState<any>({})
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')
        // @ts-ignore
        const customer = await getCustomerInfo(customerId)
        setCustomer(customer)
          localStorage.setItem('planning_credit_available', customer.planning_credit_available)
      } catch (error) {
        console.error('Error fetching customer', error)
        navigate('/error/404')
      }
    }

    fetchData()
  }, [navigate])

  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_renewal_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);

  return (
    <>
      <TitleManager title='Renouvellement - Sleem' />
      <div className="card" id="kt_pricing">
        <div className="card-body p-lg-17">
          <div className="d-flex flex-column">
            <div className="mb-13 text-center">
              {goodTextForRenewal(customer)}
            </div>

            <div className="row g-10">
              <div className="col-xl-4">
                <div className="d-flex h-100 align-items-center">
                  <div className="w-100 d-flex flex-column flex-center rounded-3 bg-danger bg-opacity-10 py-15 px-10">
                    <div className="mb-7 text-center">
                      <h1 className=" mb-2 fw-bolder">Radis-cale</h1>
                      <h1 className="fw-bolder mb-5"> 1 mois </h1>
                      <div className="text-center">
                        <span className="fs-3x fw-bold text-danger" data-kt-plan-price-month="39"
                              data-kt-plan-price-annual="399">25,00 €</span>
                      </div>
                      <div className="text-center mb-5">
                        <span className="mb-2 ">soit </span>
                        <span className="fs-5 fw-bold text-danger" data-kt-plan-price-month="39"
                              data-kt-plan-price-annual="399">6,25 €</span>
                        <span className="fs-7 fw-semibold opacity-50">/ <span data-kt-element="period">semaine</span>
                                </span>
                      </div>
                    </div>
                    <div className="w-100 mb-10">
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"><span
                            className="fw-bolder">4 plannings</span> de menu </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                          fill="#F40F63"></rect>
                                    <path
                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                        fill="#F40F63"></path>
                                  </svg>
                                </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span
                            className="fw-bolder">recettes</span> détaillées</span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                              fill="#F40F63"></rect>
                                        <path
                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                            fill="#F40F63"></path>
                                    </svg>
                                </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span className="fw-bolder">listes de courses</span> </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                              fill="#F40F63"></rect>
                                        <path
                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                            fill="#F40F63"></path>
                                    </svg>
                                </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"><span
                            className="fw-bolder">1 échange</span> téléphonique </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                              fill="#F40F63"></rect>
                                        <path
                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                            fill="#F40F63"></path>
                                    </svg>
                                </span>
                      </div>


                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1"> Accès au <span
                            className="fw-bolder">groupe facebook</span> </span>
                        <span className="svg-icon svg-icon-1">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                          fill="currentColor"></rect>
                                    <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                          transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                    <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                          transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                  </svg>
                                </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1"><span
                            className="fw-bolder">Fonctionnalités</span> en avant première </span>
                        <span className="svg-icon svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                              fill="currentColor"></rect>
                                        <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                              transform="rotate(-45 7 15.3137)" fill="currentColor">
                                        </rect>
                                        <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                              transform="rotate(45 8.41422 7)" fill="currentColor">
                                        </rect>
                                    </svg>
                                </span>
                      </div>

                    </div>
                    <a href="https://buy.stripe.com/9AQ8wP6Zl4WaeTSdRc" className="btn btn-sm btn-danger">Je
                      commande</a>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="d-flex h-100 align-items-center">
                  <div className="w-100 d-flex flex-column flex-center rounded-3 bg-danger bg-opacity-10 py-15 px-10">
                    <div className="mb-7 text-center">
                      <h1 className=" mb-2 fw-bolder">Bouchées doubles</h1>
                      <h1 className=" fw-bolder mb-5">3 mois</h1>
                      <div className="text-center">
                                        <span className="fs-3x fw-bold text-danger" data-kt-plan-price-month="39"
                                              data-kt-plan-price-annual="399">70,00 €</span>
                      </div>
                      <div className="text-center mb-5">
                        <span className="mb-2 ">soit </span>
                        <span className="fs-5 fw-bold text-danger" data-kt-plan-price-month="39"
                              data-kt-plan-price-annual="399">5,83 €</span>
                        <span className="fs-7 fw-semibold opacity-50">/ <span data-kt-element="period">semaine</span>
                                        </span>
                      </div>

                    </div>
                    <div className="w-100 mb-10">
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 flex-grow-1 pe-3"><span
                            className="fw-bolder">12 plannings</span> de menu </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="#F40F63"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="#F40F63"></path>
                                        </svg>
                                    </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span
                            className="fw-bolder">recettes</span> détaillées</span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="#F40F63"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="#F40F63"></path>
                                        </svg>
                                    </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span className="fw-bolder">listes de courses</span> </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="#F40F63"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="#F40F63"></path>
                                        </svg>
                                    </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"><span
                            className="fw-bolder">3 échanges</span> téléphonique </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="#F40F63"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="#F40F63"></path>
                                        </svg>
                                    </span>
                      </div>


                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 flex-grow-1"> Accès au <span className="fw-bolder">groupe facebook</span> </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="#F40F63"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="#F40F63"></path>
                                        </svg>
                                    </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1"><span
                            className="fw-bolder">Fonctionnalités</span> en avant première </span>
                        <span className="svg-icon svg-icon-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                  transform="rotate(-45 7 15.3137)"
                                                  fill="currentColor">
                                            </rect>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                  transform="rotate(45 8.41422 7)"
                                                  fill="currentColor">
                                            </rect>
                                        </svg>
                                    </span>
                      </div>

                    </div>
                    <a href="https://buy.stripe.com/14kdR93N9fAO8vucN9" className="btn btn-sm btn-danger">Je
                      commande</a>
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="d-flex h-100 align-items-center">
                  <div className="w-100 d-flex flex-column flex-center rounded-3 bg-danger bg-opacity-10 py-15 px-10">
                    <div className="mb-7 text-center">
                      <h1 className="mb-2 fw-bolder">Crème de la crème</h1>
                      <h1 className=" fw-bolder mb-5">6 mois</h1>
                      <div className="text-center">
                                        <span className="fs-3x fw-bold text-danger" data-kt-plan-price-month="39"
                                              data-kt-plan-price-annual="399">120,00 €</span>
                      </div>

                      <div className="text-center mb-5">
                        <span className="mb-2">soit </span>
                        <span className="fs-5 fw-bold text-danger" data-kt-plan-price-month="39"
                              data-kt-plan-price-annual="399">5,00 €</span>
                        <span className="fs-7 fw-semibold opacity-50">/ <span data-kt-element="period">semaine</span>
                                        </span>
                      </div>

                    </div>
                    <div className="w-100 mb-10">
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"><span
                            className="fw-bolder">24 plannings</span> de menu </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span
                            className="fw-bolder">recettes</span> détaillées</span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"> Les <span className="fw-bolder">listes de courses</span> </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6  flex-grow-1 pe-3"><span
                            className="fw-bolder">6 échanges</span> téléphonique </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>


                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 flex-grow-1"> Accès au <span className="fw-bolder">groupe facebook</span> </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6   flex-grow-1"><span
                            className="fw-bolder">Fonctionnalités</span> en avant première </span>
                        <span className="svg-icon svg-icon-1 svg-icon-success">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                      fill="#F40F63"></rect>
                                                <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="#F40F63"></path>
                                            </svg>
                                        </span>
                      </div>

                    </div>
                    <a href="https://buy.stripe.com/28o00j6Zl1JYfXWbJ6" className="btn btn-sm btn-danger">Je
                      commande</a>
                  </div>
                </div>
              </div>


                <div className="d-flex justify-content-center text-center fw-semibold align-items-center">
                    📅 Payez maintenant, profitez plus tard
                </div>
                <div className="d-flex justify-content-center text-center fw-semibold align-items-center mt-3">
                    🛑 Aucun renouvellement automatique
                </div>
                <div className="d-flex justify-content-center text-center fw-semibold align-items-center mt-3">
                    💰 14 jours satisfait ou remboursé
                </div>
              <div className="d-flex justify-content-center fw-semibold align-items-center text-center">
                <span>Si vous avez la moindre question, n'hésitez pas à nous contacter par e-mail à <a
                    href="mailto:hello@sleem.io" className="fw-bolder text-danger"> hello@sleem.io</a> ou par téléphone au <a
                    href="tel:0179753020" className="fw-bolder text-danger">01 79 75 30 20</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export {RenewalWrapper}
