import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TitleManager from "../../../_metronic/layout/components/title-manager/TitleManager";
import {PageTitle} from "../../../_metronic/layout/core";
import {getFaqList} from "./_requests";
import { Parser } from 'html-to-react';
import {brevoTrackEvent} from "../../modules/brevo/_requests";

const FaqList: FC = () => {
  const [faqs, setFaqs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
  is used to fetch data and update the component's state. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await getFaqList()
        setFaqs(items.results)

        setIsLoading(false)

      } catch (error) {
        console.error('Error fetching faq list', error)
        navigate('/error/404')
      }
    }
    fetchData()
  }, [navigate])


  // BREVO TRACKING
  useEffect(() => {
    try {
      const eventName = "has_viewed_faq_page"
      const eventData = {}
      brevoTrackEvent(
          eventName,
          eventData
      )
      console.log('Success - brevoTrackEvent');
    } catch (error) {
      console.error(error)
    }
  }, []);


  if (isLoading) return <div>Chargement...</div>


  return (
    <>
      <TitleManager title='Faq - Sleem' />
      <PageTitle>Foire aux questions</PageTitle>
      <div className="col-lg-12 col-xl-12 col-sm-12 ">
        {faqs.map((category, index) => (
              <div key={category.id} className={'mb-10'}>
                <h3>{category.name}</h3>
                {category.faqs.map((faq, index) => (
                    <div key={faq.id} className="accordion accordion-flush" id="accordionFlushExample">
                      <div className="accordion-item">
                      <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${faq.id}`} aria-expanded="false"
                                aria-controls={`flush-collapse${faq.id}`}>
                          <b>{faq.question}</b>
                        </button>
                      </h2>
                      <div id={`flush-collapse${faq.id}`} className="accordion-collapse collapse"
                           aria-labelledby={`flush-heading${faq.id}`}
                           data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">{Parser().parse(faq.answer)}</div>
                      </div>
                    </div>
                    </div>
              ))}
              </div>
        ))}
      </div>

    </>
  )
}

export {FaqList}
