/* eslint-disable jsx-a11y/anchor-is-valid */
import {ShowRemoveMealRecipe} from '../components/ShowRemoveMealRecipe'
import {useState} from 'react'

type Step3Props = {
  mealData: any
  onIngredientSelectionChange: (selectedIngredients: string[]) => void
}

const Step3: React.FC<Step3Props> = ({mealData, onIngredientSelectionChange}) => {
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([])

  const handleCheckboxChange = (ingredientId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedIngredients((prev) => [...prev, ingredientId])
    } else {
      setSelectedIngredients((prev) => prev.filter((id) => id !== ingredientId))
    }
    onIngredientSelectionChange(selectedIngredients)
  }

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100 text-center'>
          <h3 className='text-center text-white mb-5'>{mealData.name}</h3>
          <div
            className='symbol w-12 h-12 me-3 mb-5'
            style={{
              backgroundImage: `url(https://res.cloudinary.com/hv0mwtwcz/${mealData.main_picture})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '150px',
              height: '150px',
            }}
          ></div>
          {/*begin::Form Group */}
          <div className='fv-row bg-white p-6 rounded'>
            <ShowRemoveMealRecipe
              className='card-xl-stretch mb-xl-8'
              ingredients={mealData.recipe_ingredients}
              onCheckboxChange={handleCheckboxChange}
            />
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export {Step3}
