const API_URL = process.env.REACT_APP_API_URL

/**
 * The function `getCourseList` fetches a custom shopping list for a specific course ID.
 * @param {string} id - The `id` parameter is a string that represents the ID of a public planning.
 * This ID is used to fetch the custom shopping list for that planning.
 * @returns the data fetched from the COURSE_LIST_URL endpoint.
 */
export const getCourseList = async (id?: string) => {
  const COURSE_LIST_URL = `${API_URL}/public-plannings/${id}/get_custom_shopping_list/`
  const response = await fetch(COURSE_LIST_URL)
  const data = await response.json()
  return data
}

/**
 * The function updates a custom shopping list item's checked value in a public planning.
 * @param {boolean} checkedValue - The `checkedValue` parameter is a boolean value that indicates
 * whether an item in the shopping list should be checked or unchecked. If `checkedValue` is `true`, it
 * means the item should be checked. If `checkedValue` is `false`, it means the item should be
 * unchecked.
 * @param {string} idItem - The `idItem` parameter is the ID of the custom shopping list item that you
 * want to update.
 * @param {string} [id] - The `id` parameter is an optional parameter that represents the ID of the
 * public planning. It is used to construct the URL for updating the custom shopping list. If provided,
 * it will be included in the URL as part of the path.
 * @returns the response from the API call in JSON format.
 */
export async function updateShoppingList(checkedValue: boolean, idItem: string, id?: string) {
  try {
    const UPDATE_SHOPPING_LIST_URL = `${API_URL}/public-plannings/${id}/update_custom_shopping_list/`
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(UPDATE_SHOPPING_LIST_URL, {
      method: 'PUT',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        custom_shopping_list_item_id: idItem,
        is_checked: checkedValue,
      }),
    })
    if (!response.ok) {
      throw new Error('Erreur lors de la mise à jour')
    }

    return response.json()
  } catch (error) {
    console.error('Erreur lors de la mise à jour', error)
    throw error
  }
}
