const API_URL = process.env.REACT_APP_API_URL

/**
 * The function `getCustomers` is an asynchronous function that fetches customer data from a specified
 * API endpoint and returns the data.
 * @param {string} customerId - The `customerId` parameter is a string that represents the unique
 * identifier of a customer. It is used to construct the URL for fetching customer data from the API.
 * @returns the data fetched from the customer API endpoint.
 */
export const getCustomers = async (customerId: string) => {
  const CUSTOMER_URL = `${API_URL}/customers/${customerId}/`
  try{
    const response = await fetch(CUSTOMER_URL, {
    credentials: 'include',
  })
  const data = await response.json()
  return data
  } catch (e) {
    console.log(e)
  }

}
