/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/my-profile'
        icon='/media/svg/icons-sleem/heart.svg'
        title='Mon profil'
      />
      <SidebarMenuItem
        to='/my-planning'
        icon='/media/svg/icons-sleem/calendar.svg'
        title='Mes plannings'
      />
      <SidebarMenuItem
        to='/my-past-recipes'
        icon='/media/svg/icons-sleem/recipes.svg'
        title='Mes recettes'
      />
        <SidebarMenuItem
        to='/assistant'
        icon='/media/svg/icons-sleem/communication.svg'
        title='Mon assistant Sleem'
      />
      <SidebarMenuItem
        to='/my-advice'
        icon='/media/svg/icons-sleem/rocket.svg'
        title='Mes conseils'
      />
      <SidebarMenuItem
        to='/my-account'
        icon='/media/svg/icons-sleem/account.svg'
        title='Mon compte'
      />
      <SidebarMenuItem
          to='/faq'
          icon='/media/svg/icons-sleem/faq.svg'
          title='FAQ' />

        <SidebarMenuItem
          to='/renewal'
          icon='/media/svg/icons-sleem/euro.svg'
          title='Je prolonge' />
    </>
  )
}

export {SidebarMenuMain}
