/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {logout} = useAuth()
  const email = localStorage.getItem('email') || ''
  const firstName = localStorage.getItem('first_name') || ''
  const lastName = localStorage.getItem('last_name') || ''
  return (
    <div
        id="overlayProfileMenu"
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <KTIcon iconName='profile-circle' className='fs-3x' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {firstName} {lastName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                Membre Sleem
              </span>
            </div>
            <a className='fw-bold text-muted text-hover-primary fs-7'>
              {email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/my-account'} className='menu-link px-5'>
          Mon compte
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/faq' className='menu-link px-5'>
          F.A.Q
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <a href='https://calendly.com/rdv-dieteticienne-sleem/30min' target='_blank' className='menu-link px-5'>
          RDV avec un expert
        </a>
      </div>

      <div className='menu-item px-5 my-1'>
        <a href='mailto:hello@sleem.io' target='_blank' className='menu-link px-5'>
          Contact
        </a>
      </div>

      <div className='menu-item px-5 my-1'>
        <a
          href='https://www.sleem.io/politique-de-confidentialite'
          target='_blank'
          className='menu-link px-5'
        >
          CGU
        </a>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Déconnexion
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
