/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ShowMealRecipe} from '../components/ShowMealRecipe'

const Step2 = ({mealData, goToStep, updateMealData}) => {
  const [showRecipe, setShowRecipe] = useState(false)

  console.log('mealData', mealData)
  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row text-center'>
          <h3 className='text-center text-white mb-5'>{mealData.name}</h3>
          <div
            className='symbol w-12 h-12 me-3'
            style={{
              backgroundImage: `url(https://res.cloudinary.com/hv0mwtwcz/${mealData.main_picture})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '150px',
              height: '150px',
            }}
          ></div>
          <div>
            <span
              onClick={() => setShowRecipe(!showRecipe)}
              className='btn btn-link-white text-white'
            >
              {showRecipe ? 'Masquer' : 'En savoir plus'}
            </span>
            {showRecipe && (
              <div id='meal_recipes' className='bg-white p-6 rounded'>
                <ShowMealRecipe
                  className='card-xl-stretch mb-xl-8'
                  ingredients={mealData.recipe_ingredients}
                  informations={mealData}
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-background-change-recipe p-5 mt-5 bg-white rounded'>
          <div className='pt-3 d-flex flex-column'>
            <p className='text-center fs-3 m-3 card-title-change-recipe'>
              Pourquoi ne voulez-vous pas cette recette ?
            </p>

            <a className='btn btn-danger m-3' onClick={() => goToStep(3)}>
              Je n'aime pas certains ingrédients
            </a>
            <a
              className='btn btn-danger m-3'
              onClick={() => {
                updateMealData()
                goToStep(4)
              }}
            >
              Autres raisons
            </a>
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {Step2}
