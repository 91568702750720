/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../app/modules/auth'

const SidebarFooter = () => {
  const {logout} = useAuth()

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        href={process.env.REACT_APP_PREVIEW_DOCS_URL}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Déconnexion'
        onClick={logout}
      >
        <span className='btn-label'>Déconnexion</span>
      </a>
    </div>
  )
}

export {SidebarFooter}
