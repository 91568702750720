import React from "react";

const Rdv = ({customer}) => {

    return (
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0" data-bs-toggle="collapse"
             data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Mes rendez-vous</h3>
          </div>
        </div>
        <div id="kt_account_settings_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row d-flex justify-content-left align-items-center">
              <div className="col-12">
                <div className="">
                    <p>Vous bénéficiez d’<b>un rendez-vous gratuit de 30 minutes par mois</b> avec un de nos experts, profitez-en !</p>
                    <p>
                      Recevez des conseils personnalisés pour vous aider à atteindre vos objectifs,
                      obtenez des réponses à toutes vos questions...</p>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-5">
              <div>
                <a href={'https://calendly.com/rdv-dieteticienne-sleem/30min'} target={"_blank"} className="btn btn-danger btn-active-light-danger">
                  Je prends rendez-vous
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Rdv
