import React from 'react'
import {useState, useEffect, useCallback} from 'react'

const NotificationSuccess = ({show, title, message}) => {
  const [isNotifVisible, setNotifVisible] = useState(show)

  useEffect(() => {
    if (show) {
      // Afficher la notification
      setNotifVisible(true)
      const timerId = setTimeout(() => {
        setNotifVisible(false)
      }, 5000)

      return () => clearTimeout(timerId)
    }
  }, [show])

  if (!isNotifVisible) return null
  /**
   * The function `handleNotifClick` sets the visibility of a notification to true, and then after 5
   * seconds, sets the visibility to false.
   * @returns The function returned is a cleanup function that clears the timeout using clearTimeout.
   */
  const handleNotifClick = () => {
    setNotifVisible(true)
    const timerId = setTimeout(() => {
      setNotifVisible(false)
    }, 5000)

    return () => {
      clearTimeout(timerId)
    }
  }

  /**
   * The function handleCloseNotif is used to close a notification by setting its visibility to false.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data related to the event. In this case, the event parameter is used to stop
   * the event from propagating further up
   */
  const handleCloseNotif = (event) => {
    event.stopPropagation()
    setNotifVisible(false)
  }
  return (
    <div
      id='notif'
      className='z-20 absolute flex-column box w-50 w-lg-325px shadow-lg bg-success'
      style={{position: 'fixed', right: '0', top: '10%', zIndex: '20'}}
    >
      <div id='header' className='d-flex py-1 flex-row justify-content-between align-items-center'>
        <div className='d-flex flex-row align-items-center gap-2 px-4 py-2 text-white w-100'>
          <i id='icone' className='far fa-check-circle text-white fs-4'></i>
          <span className='fs-5' id='title'>
            {title}
          </span>
        </div>

        <button
          id='close_notification'
          type='button'
          className='btn btn-icon-success'
          onClick={handleCloseNotif}
        >
          <span className='svg-icon svg-icon-1 close-notif'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.5'
                x='6'
                y='17.3137'
                width='16'
                height='2'
                rx='1'
                transform='rotate(-45 6 17.3137)'
                fill='#ffff'
              ></rect>
              <rect
                x='7.41422'
                y='6'
                width='16'
                height='2'
                rx='1'
                transform='rotate(45 7.41422 6)'
                fill='#ffff'
              ></rect>
            </svg>
          </span>
        </button>
      </div>
      <div id='description' className='d-flex align-items-center text-dark bg-white px-4 py-4'>
        {message}
      </div>
    </div>
  )
}

export default NotificationSuccess
