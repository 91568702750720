const API_URL = process.env.REACT_APP_API_URL

/**
 * The function `getRecipes` retrieves past recipes for a customer within the last 180 days.
 * @param {string} customerId - The `customerId` parameter is a string that represents the unique
 * identifier of a customer. It is used to fetch past recipes for a specific customer.
 * @returns the data fetched from the API endpoint.
 */
export const getRecipes = async (customerId: string) => {
  const RECIPES_URL = `${API_URL}/customers/${customerId}/get_past_recipes_by_recipe_type/?past_days=180`

  const response = await fetch(RECIPES_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data
}

/**
 * The function `getRecipeDetail` is an asynchronous function that fetches recipe details from a
 * specified API endpoint and returns the data.
 * @param {string} recipeId - The `recipeId` parameter is a string that represents the unique
 * identifier of a recipe. It is used to fetch the details of a specific recipe from the server.
 * @returns the data fetched from the API endpoint.
 */
export const getRecipeDetail = async (recipeId: string) => {
  const RECIPES_URL = `${API_URL}/public-recipes/${recipeId}/`

  const response = await fetch(RECIPES_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data
}

/**
 * The function `getRecipeReviews` retrieves recipe reviews for a specific recipe and customer.
 * @param {string} id - The `id` parameter represents the ID of the recipe for which you want to
 * retrieve the reviews.
 * @param {string} cusomerId - The `cusomerId` parameter is the ID of the customer for whom you want to
 * retrieve recipe reviews.
 * @returns the data fetched from the API endpoint.
 */
export const getRecipeReviews = async (id: string, customerId: string) => {
  const RECIPES_REVIEWS_URL = `${API_URL}/customer-recipe-reviews/custom_get_customer_recipe_review/?customer_id=${customerId}&recipe_id=${id}`

  const response = await fetch(RECIPES_REVIEWS_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data
}

/**
 * The `addRecipeReview` function is an asynchronous function that sends a POST request to add a review
 * for a recipe, including the customer ID, recipe ID, general rating (as an integer), and general
 * rating text.
 * @param customerId - The `customerId` parameter is the unique identifier of the customer who is
 * adding the recipe review. It is used to associate the review with the customer's account.
 * @param recipeId - The `recipeId` parameter is the unique identifier of the recipe for which the
 * review is being added.
 * @param generalRatingInt - The generalRatingInt parameter is an integer value representing the
 * general rating of the recipe. It is typically a number between 1 and 5, where 1 is the lowest rating
 * and 5 is the highest rating.
 * @param generalRatingText - The parameter `generalRatingText` is a string that represents the text
 * review of the recipe. It is used to provide additional details or comments about the recipe.
 * @returns the data received from the API after successfully adding a recipe review.
 */
export const addRecipeReview = async (
  customerId,
  recipeId,
  generalRatingInt,
  generalRatingText
) => {
  const ADD_REVIEW_URL = `${API_URL}/customers/${customerId}/add_recipe_review/`

  try {
    const csrftoken = localStorage.getItem('csrftoken')
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    }
    if (csrftoken !== null) {
      headers['X-CSRFToken'] = csrftoken
    }
    const response = await fetch(ADD_REVIEW_URL, {
      method: 'POST',
      credentials: 'include',
      headers: headers,
      body: JSON.stringify({
        customer_id: customerId,
        recipe_id: recipeId,
        general_rating_int_5: generalRatingInt,
        general_rating_text: generalRatingText,
      }),
    })

    if (!response.ok) {
      throw new Error("Erreur lors de l'ajout de l'avis")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'avis", error)
    throw error
  }
}
