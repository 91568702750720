import React, {useState} from 'react'
import {ShowMealRecipe} from '../components/ShowMealRecipe'

type Step1Props = {
  mealData: any
}

const Step1: React.FC<Step1Props> = ({mealData}) => {
  const [showRecipe, setShowRecipe] = useState(false)

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100 text-center'>
        <h3 className='text-center text-white mb-5'>{mealData.name}</h3>

        <div
          className='symbol w-12 h-12 me-3'
          style={{
            backgroundImage: `url(https://res.cloudinary.com/hv0mwtwcz/${mealData.main_picture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '150px',
            height: '150px',
          }}
        ></div>
        <div>
          <span
            onClick={() => setShowRecipe(!showRecipe)}
            className='btn btn-link-white text-white'
          >
            {showRecipe ? 'Masquer' : 'En savoir plus'}
          </span>
          {showRecipe && (
            <div id='meal_recipes' className='bg-white p-6 rounded'>
              <ShowMealRecipe
                className='card-xl-stretch mb-xl-8'
                ingredients={mealData.recipe_ingredients}
                informations={mealData}
              />
            </div>
          )}
        </div>
        <p className='text-white pt-5'>Souhaitez-vous modifier cette recette ?</p>
      </div>
    </div>
  )
}

export {Step1}
