import React, {useEffect, useState} from 'react'
import {getCustomerInfo, updateSubStatus} from "./_requests";

const NotificationToggles = ({customer}) => {
    let [smsNotificationValue, setSmsNotificationValue] = useState(customer.subscribed_sms);
    let [nlNotificationValue, setNlNotificationValue] = useState(customer.subscribed_newsletter);
    let [subscribedValue, setSubscribedValue] = useState(customer.subscribed);

    useEffect(() => {
    const fetchData = async () => {
      try {
        const customerId = localStorage.getItem('customer_id')!
        const customer = await getCustomerInfo(customerId)
          setSmsNotificationValue(customer.subscribed_sms)
          setNlNotificationValue(customer.subscribed_newsletter)
          setSubscribedValue(customer.subscribed)

      } catch (error) {
        console.error('Error fetching customer', error)
      }
    }

    fetchData()
  }, [])

    const handleChange = (e, toggleName) => {
        let checkBoxValue = e.target.checked
        if (toggleName === "sms") {
            setSmsNotificationValue(checkBoxValue)
            smsNotificationValue = checkBoxValue
        } else if (toggleName === "newsletter") {
            setNlNotificationValue(checkBoxValue)
            nlNotificationValue = checkBoxValue
        } else if (toggleName === "all") {
            setSubscribedValue(checkBoxValue)
            subscribedValue = checkBoxValue
        }

        updateSubStatus(
            customer.id,
            smsNotificationValue,
            nlNotificationValue,
            subscribedValue
        )
  };

  return (
      <>
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0" data-bs-toggle="collapse"
                 data-bs-target="#kt_account_signin_method">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Mes notifications</h3>
              </div>
            </div>
            <div id="kt_account_settings_signin_method" className="collapse show">
              <div className="card-body border-top p-9">
                <div className="row d-flex justify-content-left align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6 mb-1">
              <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                      className="form-check-input"
                      type="checkbox"
                      id="smsNotificationToggle"
                      checked={smsNotificationValue}
                      onChange={event => handleChange(event, "sms")}
                  />
                <label className="form-check-label" htmlFor="flexSwitchDefault">
                  Nos SMS de notification
                </label>
              </div>
            </div>

          <div className="col-sm-12 col-md-12 col-lg-6 mb-1">
              <div className="form-check form-switch form-check-custom form-check-solid">

                  <input
                      className="form-check-input"
                      type="checkbox"
                      id="newsletterNotificationToggle"
                      checked={nlNotificationValue}
                      onChange={event => handleChange(event, "newsletter")}
                  />

                <label className="form-check-label" htmlFor="flexSwitchDefault">
                  Notre newsletter (conseils, astuces, recettes...)
                </label>
              </div>
            </div>


                </div>
              </div>
            </div>
          </div>

          <div className="card mb-5 mb-xl-10">
              <div className="card-header border-0" data-bs-toggle="collapse"
             data-bs-target="#kt_account_signin_method">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Mon abonnement</h3>
          </div>
        </div>
        <div id="kt_account_settings_signin_method" className="collapse show">
          <div className="card-body border-top p-9">
            <div className="row d-flex justify-content-left align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-12">

                <div className="col-12">
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input
                      className="form-check-input"
                      type="checkbox"
                      id="subscribedNotificationToggle"
                      checked={subscribedValue}
                      onChange={event => handleChange(event, "all")}
                  />
                  <label className="form-check-label" htmlFor="flexSwitchDefault">
                      Abonnement actif
                  </label>
                </div>
                    <div className="mt-5">
                        <p className='text-danger flex-column'>Attention, en décochant cette case, vous serez instantanément désinscrit de notre
                    programme et ne recevrez plus aucun planning, mail, SMS.
                        </p>
                    </div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      </>
  )
}

export default NotificationToggles;
